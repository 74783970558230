import styled from "styled-components";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";

import healthImage from "assets/health.svg";
import expImage from "assets/exp.svg";
import LargeBar from "./LargeBar";
import { COLORS } from "utils/constants";

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export default function Status() {
  const {
    data: { currentLevelExp, nextLevelExp, health, derivedStats },
  } = useSelector(getCharacter);

  return (
    <StatusWrapper>
      <div>
        <Name>Ship Health</Name>
        <LargeBar
          icon={healthImage}
          number={health}
          maxNumber={derivedStats.current.complete.maxHealth}
          color={COLORS.HEALTH}
          name="Health"
          description="This is how much health your ship currently has. Don't let it drop to zero! You can fully repair your starship at the nearest Shipworks in any city."
        />
      </div>
      <div>
        <Name>Experience</Name>
        <LargeBar
          icon={expImage}
          number={currentLevelExp}
          maxNumber={nextLevelExp}
          color={COLORS.EXPERIENCE}
          name="Experience"
          description="This is how much experience you have in your current level. Gain more experience to level up!"
        />
      </div>
    </StatusWrapper>
  );
}
