import styled from "styled-components";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  TooltipValues,
  Trigger,
  ValuesIcon,
} from "./Tooltip";

interface BarProps {
  name: string;
  description: string;
  icon: string;
  number: number;
  maxNumber: number;
  color: string;
}

interface BarMaxProps {
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface BarIconProps {
  image: string;
  color: string;
}

const BarWrapper = styled.div`
  width: 100%;
  height: 20px;
`;

const BarContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: skew(-30deg);
`;

const BarIconWrapper = styled.div`
  position: absolute;
  right: -20px;
  z-index: 3;
  transform: skew(30deg);
`;

const BarIcon = styled.div<BarIconProps>`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const BarMax = styled.div<BarMaxProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => props.color};
  filter: saturate(75%) brightness(60%);
`;

const BarValues = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  height: 100%;
  background-color: ${(props) => props.color};
  transition: all 200ms ease-out;
`;

const BarExtraValue = styled(BarValue)`
  filter: brightness(110%);
  background: ${(props) => props.color}
    repeating-linear-gradient(
      90deg,
      #ffffff20,
      #ffffff20 10px,
      transparent 10px,
      transparent 20px
    );
`;

const BarMaxNumber = styled.div`
  height: 100%;
  color: #00000060;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  margin-right: 8px;
  transform: skew(30deg);
  z-index: 1;
`;

const BarValueNumber = styled.div`
  height: 100%;
  position: absolute;
  left: 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  transform: skew(30deg);
  z-index: 3;
`;

export default function Bar({
  name,
  description,
  icon,
  number,
  maxNumber,
  color,
}: BarProps) {
  // Account for cases where number is higher than max number
  let baseAmount = Math.min(number, maxNumber);
  let extraAmount = Math.max(number - maxNumber, 0);
  let actualMaxAmount = Math.max(number, maxNumber);

  const basePercentage = (baseAmount / actualMaxAmount) * 100;
  const extraPercentage = (extraAmount / actualMaxAmount) * 100;

  return (
    <BarWrapper>
      <Trigger>
        <BarContent>
          <BarIconWrapper>
            <BarIcon image={icon} color={color} />
          </BarIconWrapper>
          <BarMax color={color}></BarMax>
          <BarMaxNumber>{maxNumber}</BarMaxNumber>
          <BarValues>
            <BarValue percentage={basePercentage} color={color} />
            <BarExtraValue percentage={extraPercentage} color={color} />
          </BarValues>
          <BarValueNumber>{number}</BarValueNumber>
        </BarContent>
        <Tooltip>
          <TooltipName>{name}</TooltipName>
          <TooltipDesc>{description}</TooltipDesc>
          <TooltipDesc>
            <TooltipValues color={color}>
              <ValuesIcon image={icon} color={color} />
              {`${name}: ${number} / ${maxNumber}`}
            </TooltipValues>
          </TooltipDesc>
        </Tooltip>
      </Trigger>
    </BarWrapper>
  );
}
