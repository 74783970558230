import {
  call,
  delay,
  put,
  race,
  select,
  spawn,
  take,
  takeLeading,
} from "redux-saga/effects";

import { CharacterBaseStats, CombatLogTypes, Fighter } from "types";

import { BASE_STATS } from "data/baseStats";
import { DERIVED_STATS } from "data/derivedStats";
import {
  activateSkill,
  addCharacterTurnPriority,
  addCombatLog,
  addEventLog,
  addLogMessage,
  addOpponentTurnPriority,
  continueFight,
  disableFightActions,
  disableMovement,
  employFightSupply,
  enableFightActions,
  enableMovement,
  endFightLose,
  endFightWin,
  escapeFight,
  fightAgain,
  healOpponent,
  moveToPosition,
  onwardFightLose,
  onwardFightWin,
  previewFight,
  rechargeOpponentSkillsTurn,
  rechargeSkillsTurn,
  resetAnimations,
  resetCombatLogs,
  resetDamage,
  resetFightResults,
  resetOpponentAnimations,
  resetOpponentDamage,
  resetOpponentParts,
  resetOpponentSkillsRecharge,
  resetOpponentSkillsValues,
  resetSkillsRecharge,
  resetSkillsValues,
  resetTurnPriorities,
  restoreAllParts,
  retreatFight,
  setFightResults,
  setFightStatus,
  setMobBattleRating,
  setupOpponent,
  showMessage,
  startFight,
  startMobDialog,
  targetReactor,
  targetShields,
  targetTargetingComputer,
  targetThrusters,
  targetWeapons,
  turnShip,
} from "redux/actions";
import {
  enterAreaSaga,
  gainCreditsSaga,
  gainExpSaga,
  healSaga,
} from "redux/sagas/character";
import { employSupplySaga, getItemsSaga } from "redux/sagas/item";
import { getCharacter, getFight } from "redux/selectors";
import {
  calculateBattleRating,
  calculateInitialMobStats,
  DODGE_WEIGHTED_THRESHOLD,
  ESCAPE_WEIGHTED_THRESHOLD,
  getBattleRatingData,
  getBuildingData,
  getChargedSkills,
  getCreditsGained,
  getExpGained,
  getMobData,
  getMobDrops,
  getOpponentBaseStatTarget,
  getRegularAttackValues,
  getSkillData,
  getSupplyData,
  getTotalDamageValuesAfterDefense,
  getWeightedAttackDiceRoll,
  getWeightedEscapeDiceRoll,
  MOB_SKILL_THRESHOLD,
  rollDice,
} from "utils/stats";
import { activateSkillSaga } from "./skill";

export const FIGHT_DISTANCE = 30;
export const ESCAPE_DISTANCE = 40;
export const ANIMATIONS = {
  attack: 300,
  damaged: 300,
  weaken: 500,
  dodge: 300,
  nullify: 500,
  escape: 500,
  lose: 3500,
};

function* startMobDialogSaga({ payload }: { payload: string }) {
  // Set up opponent for fight so we can see their stats
  yield call(setupOpponentSaga, payload);

  // Show opening dialog from Mob
  yield put(setFightStatus("openingDialog"));
}

function* previewFightSaga() {
  // Restrict movement of character
  yield put(disableMovement());

  // Reset skills recharging just in case
  yield put(resetSkillsRecharge());

  // Reset skills values just in case
  yield put(resetSkillsValues());

  // Show fight preview window
  yield put(setFightStatus("preview"));
}

function* retreatFightSaga() {
  // Enable character movement
  yield put(enableMovement());

  // Show fight preview window
  yield put(setFightStatus("notFighting"));
}

// startFightSaga
function* startFightSaga() {
  // Set fighting status
  yield put(setFightStatus("fighting"));

  // Set initial attack priority for character and opponent
  const {
    userName,
    data: { derivedStats },
  } = yield select(getCharacter);
  const characterAttackSpeed = derivedStats.current.complete.attackSpeed;
  const { opponent } = yield select(getFight);
  const { slug, derivedStats: opponentDerivedStats } = opponent;
  const opponentAttackSpeed = opponentDerivedStats.current.complete.attackSpeed;
  yield put(addCharacterTurnPriority(characterAttackSpeed));
  yield put(addOpponentTurnPriority(opponentAttackSpeed));

  // Position ship in front of opponent and face to the right
  yield put(turnShip("right"));
  const { position: mobPosition } = getMobData(slug);
  yield put(moveToPosition(mobPosition - FIGHT_DISTANCE));

  yield put(
    addCombatLog({
      type: "start",
      fighter: null,
      message: `Battle begins between ${userName} and ${opponent.name}`,
    })
  );

  // Get into fight saga
  yield call(fightSaga);
}

// continueFight (calls just fightSaga?)
function* continueFightSaga() {
  const { status } = yield select(getFight);

  if (status === "fighting") {
    // Get into fight saga
    yield call(fightSaga);
  }
}

function* fightAgainSaga() {
  const { opponent } = yield select(getFight);

  // Reset fight data
  yield call(resetFightSaga);

  // Set up opponent health again
  yield call(setupOpponentSaga, opponent.slug);

  // Start fight
  yield call(startFightSaga);
}

function* fightSaga() {
  // Set fight actions as disabled by default
  yield put(disableFightActions());

  // Fight
  while (true) {
    // Get new attack priority each turn, because it may change with weakened parts
    const {
      data: { derivedStats },
    } = yield select(getCharacter);
    const characterAttackSpeed = derivedStats.current.complete.attackSpeed;
    const {
      opponent: { derivedStats: opponentDerivedStats },
      isCharacterCurrentTurn,
    } = yield select(getFight);
    const opponentAttackSpeed =
      opponentDerivedStats.current.complete.attackSpeed;

    // check whose priority is higher, it's their turn to attack
    if (isCharacterCurrentTurn) {
      // CHARACTER'S TURN
      yield put(enableFightActions());

      // Character attack sequence
      yield call(characterOptionsSaga);

      // Check if should end fight due to winning or escaping
      const {
        status,
        opponent: { health: opponentHealth },
      } = yield select(getFight);

      // Check if character has successfully escaped, then end fight
      if (status === "notFighting") {
        return false;
      }

      // Check if Opponent has died, then end fight
      if (opponentHealth === 0) {
        yield call(characterWinSaga);
        return false;
      }

      // Increase priority for opponent based on their speed
      yield put(addOpponentTurnPriority(opponentAttackSpeed));
    } else {
      // OPPONENT'S TURN

      // Give it a sec to sink in that it's the mob's turn
      yield delay(1000);

      // Opponent attack sequence
      yield call(opponentOptionsSaga);

      // Check if Characer has died, then end fight
      const {
        data: { health },
      } = yield select(getCharacter);
      if (health === 0) {
        yield call(characterLoseSaga);
        return false;
      }

      // Increase priority for character based on their speed
      yield put(addCharacterTurnPriority(characterAttackSpeed));
    }

    // Every turn, tick down skills recharging turns for character and opponent
    yield put(rechargeSkillsTurn());
    yield put(rechargeOpponentSkillsTurn());

    // Enough time between turns so mob attack isn't immediate
    yield delay(500);
  }
}

function* setupOpponentSaga(payload: string) {
  const slug = payload;
  const { derivedStats } = calculateInitialMobStats(slug);

  // Set up health from max health, including boosts from mob upgrades
  const health = derivedStats.total.complete.maxHealth;

  // Save opponent data and stats into redux fight state
  yield put(
    setupOpponent({
      slug,
      health,
    })
  );
}

export function* characterOptionsSaga() {
  // Show options on mob to target

  // Wait for character to target a specific ship part
  const {
    weapons,
    shields,
    thrusters,
    targetingComputer,
    reactor,
    skill,
    supply,
    escape,
  } = yield race({
    weapons: take(targetWeapons),
    shields: take(targetShields),
    thrusters: take(targetThrusters),
    targetingComputer: take(targetTargetingComputer),
    reactor: take(targetReactor),
    skill: take(activateSkill),
    supply: take(employFightSupply),
    escape: take(escapeFight),
  });
  const {
    data: { fighterData: characterFighter },
  } = yield select(getCharacter);
  const {
    opponent: { fighterData: opponentFighter },
  } = yield select(getFight);

  if (weapons) {
    yield put(disableFightActions());
    yield call(
      regularAttackSaga,
      characterFighter,
      opponentFighter,
      BASE_STATS.FIREPOWER
    );
  }
  if (shields) {
    yield put(disableFightActions());
    yield call(
      regularAttackSaga,
      characterFighter,
      opponentFighter,
      BASE_STATS.RESILIENCE
    );
  }
  if (thrusters) {
    yield put(disableFightActions());
    yield call(
      regularAttackSaga,
      characterFighter,
      opponentFighter,
      BASE_STATS.SPEED
    );
  }
  if (targetingComputer) {
    yield put(disableFightActions());
    yield call(
      regularAttackSaga,
      characterFighter,
      opponentFighter,
      BASE_STATS.PRECISION
    );
  }
  if (reactor) {
    yield put(disableFightActions());
    yield call(
      regularAttackSaga,
      characterFighter,
      opponentFighter,
      BASE_STATS.ENERGY
    );
  }
  if (skill) {
    yield put(disableFightActions());
    yield call(
      activateSkillSaga,
      characterFighter,
      opponentFighter,
      skill.payload
    );
  }
  if (supply) {
    yield put(disableFightActions());
    yield call(employSupplySaga, {
      payload: { slug: supply.payload, showMessage: false },
    });

    const supplyInfo = getSupplyData(supply.payload);
    if (supplyInfo.type === "health") {
      yield put(
        addCombatLog({
          type: "heal",
          fighter: characterFighter.name,
          message: `${characterFighter.name} employed a ${supplyInfo.name}, repairing ship health by <strong>${supplyInfo.payload}</strong>`,
        })
      );
    } else if (supplyInfo.type === "integrity") {
      yield put(
        addCombatLog({
          type: "heal",
          fighter: characterFighter.name,
          message: `${characterFighter.name} employed a ${supplyInfo.name}, restoring ship stats by <strong>${supplyInfo.payload}</strong>`,
        })
      );
    }
  }
  if (escape) {
    yield put(disableFightActions());
    yield call(escapeFightSaga);
  }
}

export function* opponentOptionsSaga(): any {
  const {
    data: {
      currentBaseStats: characterBaseStats,
      fighterData: characterFighter,
    },
  } = yield select(getCharacter);
  const {
    opponent: {
      baseStatsTargets: opponentTargets,
      fighterData: opponentFighter,
      skills,
      skillsRecharge,
    },
  } = yield select(getFight);

  // Get base stat target from mob
  const baseStatWeakened = getOpponentBaseStatTarget(
    opponentTargets,
    characterBaseStats
  );

  // Check if skill is available to use, roll dice to see if it happens
  const chargedSkills = getChargedSkills(skills, skillsRecharge);
  if (chargedSkills.length > 0) {
    // Skills are available to use
    const diceRoll = rollDice();
    if (diceRoll > MOB_SKILL_THRESHOLD) {
      // Use skill
      yield call(
        activateSkillSaga,
        opponentFighter,
        characterFighter,
        chargedSkills[0].slug
      );
      return;
    }
  }

  // Otherwise opponent does a regular attack
  yield call(
    regularAttackSaga,
    opponentFighter,
    characterFighter,
    baseStatWeakened
  );
}

export function* regularAttackSaga(
  attacker: Fighter,
  defender: Fighter,
  baseStatWeakened: keyof CharacterBaseStats
) {
  let attackDamage = 0;
  let attackWeakenParts = 0;
  let isCriticalHit = false;
  let isDodge = false;
  let combatLogType: CombatLogTypes = "attack";
  let combatLogMessage = "";

  ({ attackDamage, attackWeakenParts, isCriticalHit, isDodge } =
    getRegularAttackValues(attacker.data.stats, defender.data.stats));

  // Reduce attack damage by defender's shields for total damage
  const { damage, weakenParts, damageReduced, isNullified } =
    getTotalDamageValuesAfterDefense(
      attackDamage,
      attackWeakenParts,
      defender.data.stats.damageReduction,
      defender.data.stats.weakenPartsReduction
    );

  yield spawn(fightAnimation, attacker, "attack");
  // Let laser hit first before showing defender damage
  yield delay(100);

  yield put(
    attacker.actions.inflictDamage({
      damage,
      weakenParts,
      baseStatWeakened,
      totalBaseStats: defender.data.totalBaseStats,
      isCriticalHit,
      damageReduced,
    })
  );

  // ANIMATIONS / COMBAT LOGS

  // If damage dealt, animate defender getting hit
  if (damage > 0 || weakenParts > 0) {
    yield spawn(fightAnimation, defender, "damaged");
    combatLogMessage = `${attacker.name} attacked, inflicting <strong>${damage}</strong> damage and weakening ${baseStatWeakened} by <strong>${weakenParts}</strong>`;
    if (isCriticalHit) combatLogMessage += ` in a critical hit`;
  }
  if (weakenParts > 0) {
    yield spawn(fightAnimation, defender, "weaken");
  }
  // Dodge animation
  if (isDodge) {
    yield spawn(fightAnimation, defender, "dodge");
    combatLogType = "dodge";
    combatLogMessage = `${attacker.name} attacked, but it was dodged`;
  }
  // If defender didn't dodge, but damage is still 0, highlight shields
  if (isNullified) {
    yield spawn(fightAnimation, defender, "nullify");
    combatLogType = "nullify";
    combatLogMessage = `${attacker.name} attacked, but it was nullified by shields`;
  }

  yield put(
    addCombatLog({
      type: combatLogType,
      fighter: attacker.name,
      message: combatLogMessage,
    })
  );
}

export function* skillAttackSaga(
  skillSlug: string,
  attacker: Fighter,
  defender: Fighter,
  skillDamage: number = 0,
  skillWeakenParts: number = 0,
  baseStatWeakened?: keyof CharacterBaseStats,
  ignoreDodge?: boolean,
  ignoreShields?: boolean,
  isCriticalHit?: boolean
) {
  // Round damage values as initial starting point for damage
  const { maxAttackDamage: attackDamageInfo, maxWeakenParts: weakenPartsInfo } =
    DERIVED_STATS;
  let attackDamage = attackDamageInfo.rounder(skillDamage);
  let attackWeakenParts = weakenPartsInfo.rounder(skillWeakenParts);
  const skillInfo = getSkillData(skillSlug);
  let combatLogType: CombatLogTypes = "attack";
  let combatLogMessage = "";

  // Determine if dodge happens for skill attack
  const weightedAttackDiceRoll = getWeightedAttackDiceRoll(
    attacker.data.stats.attackAccuracy,
    defender.data.stats.attackEvasion
  );
  const isDodge = weightedAttackDiceRoll <= DODGE_WEIGHTED_THRESHOLD;
  if (!ignoreDodge && isDodge) {
    // Defender dodges skill attack
    attackDamage = 0;
    attackWeakenParts = 0;
    yield spawn(fightAnimation, defender, "dodge");
    combatLogType = "dodge";
    combatLogMessage = `${attacker.name} used ${skillInfo.name}, but it was dodged`;
  }

  // Otherwise figure out damage
  let damage = attackDamage;
  let weakenParts = attackWeakenParts;
  let damageReduced = 0;
  let isNullified = false;

  // See if skill ignores defender's skills
  if (!ignoreShields) {
    // Reduce attack damage by defender's shields for total damage
    ({ damage, weakenParts, damageReduced, isNullified } =
      getTotalDamageValuesAfterDefense(
        attackDamage,
        attackWeakenParts,
        defender.data.stats.damageReduction,
        defender.data.stats.weakenPartsReduction
      ));
  }

  // Apply damage to opponent health and weaken base stats
  yield put(
    attacker.actions.inflictDamage({
      damage,
      weakenParts,
      baseStatWeakened,
      totalBaseStats: defender.data.totalBaseStats,
      isCriticalHit: !!isCriticalHit,
      damageReduced,
    })
  );

  // ANIMATIONS / COMBAT LOGS

  // If damage dealt, animate mob getting hit
  if (damage > 0 || weakenParts > 0) {
    yield spawn(fightAnimation, defender, "damaged");
    combatLogMessage = `${attacker.name} used ${skillInfo.name}, inflicting <strong>${damage}</strong> damage`;
    if (baseStatWeakened) {
      combatLogMessage += ` and weakening ${baseStatWeakened} by <strong>${weakenParts}</strong>`;
    }
    if (isCriticalHit) combatLogMessage += ` in a critical hit`;
  }
  if (weakenParts > 0) {
    yield spawn(fightAnimation, defender, "weaken");
  }
  // If mob didn't dodge, but damage is still 0, highlight shields
  if (isNullified) {
    yield spawn(fightAnimation, defender, "nullify");
    combatLogType = "nullify";
    combatLogMessage = `${attacker.name} used ${skillInfo.name}, but it was nullified by shields`;
  }

  yield put(
    addCombatLog({
      type: combatLogType,
      fighter: attacker.name,
      message: combatLogMessage,
    })
  );
}

export function* fightAnimation(
  fighter: Fighter,
  animation: keyof typeof ANIMATIONS
) {
  yield put(fighter.actions.startAnimation(animation));

  const animationTime = ANIMATIONS[animation];
  yield delay(animationTime);

  // Reset animation
  yield put(fighter.actions.endAnimation(animation));
}

function* escapeFightSaga() {
  // Calculate chance of fleeing
  const {
    userName,
    data: { derivedStats, fighterData: characterFighter },
  } = yield select(getCharacter);
  const characterStats = derivedStats.current.complete;
  const {
    opponent: { derivedStats: opponentDerivedStats },
  } = yield select(getFight);
  const opponentStats = opponentDerivedStats.current.complete;

  // Animate escape attempt
  yield spawn(fightAnimation, characterFighter, "escape");
  yield delay(500);

  // Roll dice on fleeing
  const weightedEscapeDiceRoll = getWeightedEscapeDiceRoll(
    characterStats.movementSpeed,
    opponentStats.movementSpeed
  );
  if (weightedEscapeDiceRoll > ESCAPE_WEIGHTED_THRESHOLD) {
    // Escape successful

    // End fight
    yield call(endFightEscapeSaga);

    // Message that it was successful
    yield put(showMessage(`You successfully escaped the battle`));
  } else {
    // Escape failed

    yield put(
      addCombatLog({
        type: "escape",
        fighter: userName,
        message: `${userName} attemped to escape, but failed`,
      })
    );
  }
}

export function* opponentHealSaga(healAmount?: number): any {
  const {
    opponent: { health, derivedStats },
  } = yield select(getFight);
  const currentMaxHealth = derivedStats.current.complete.maxHealth;

  // If max health is lower than current health, don't heal
  if (health > derivedStats.current.complete.maxHealth) {
    return;
  }

  // If no amount is passed through, heal to max health
  const healthGained = healAmount || currentMaxHealth;

  // Don't heal past max health
  const totalHealthGained = Math.min(currentMaxHealth - health, healthGained);

  yield put(healOpponent(totalHealthGained));
}

// End Fight Sagas

function* characterWinSaga() {
  // Get data from opponent
  const {
    userName,
    data: { battleRatings, damage: damageTaken },
  } = yield select(getCharacter);
  const {
    opponent: {
      slug: mobSlug,
      damage: damageDealt,
      fighterData: opponentFighter,
    },
  } = yield select(getFight);
  const { level: mobLevel, drops, name: mobName } = getMobData(mobSlug);

  // Animate opponent losing
  yield delay(200);
  yield spawn(fightAnimation, opponentFighter, "lose");

  // Calculate Battle Performance - will affect exp, credits, drops
  const battleRating = calculateBattleRating(damageDealt, damageTaken);
  const battleRatingData = getBattleRatingData(battleRating);

  // If mob battle rating is better than previous battle, update it
  let shouldUpdateBattleRating = false;
  const previousBattleRating = battleRatings[mobSlug];
  if (!previousBattleRating) {
    shouldUpdateBattleRating = true;
  } else {
    const previousBattleRatingData = getBattleRatingData(previousBattleRating);
    if (battleRatingData.value > previousBattleRatingData.value) {
      shouldUpdateBattleRating = true;
    }
  }

  if (shouldUpdateBattleRating) {
    yield put(setMobBattleRating({ mob: mobSlug, rating: battleRating }));

    if (battleRating === "platinum") {
      // Add to achievements log, if platinum for the first time
      yield put(
        addLogMessage(
          `${userName} has earned a ${battleRatingData.name} battle rating on ${mobName}!`
        )
      );
    }
  }

  // Gain experience and credits based on mob's level and battle rating
  const expGained = getExpGained(mobLevel, battleRatingData.rewardMultiplier);
  const creditsGained = getCreditsGained(
    mobLevel,
    battleRatingData.rewardMultiplier
  );
  const dropsGained = getMobDrops(drops, battleRating);

  yield call(gainExpSaga, expGained);
  yield call(gainCreditsSaga, creditsGained);
  if (dropsGained.length > 0) {
    // Character got new supplies or upgrades
    try {
      // Gain supplies or upgrades
      yield call(getItemsSaga, { payload: dropsGained });
    } catch (error: any) {}
  }

  // Show fight results
  yield put(setFightStatus("winResults"));
  yield put(
    setFightResults({
      battleRating: battleRating,
      experience: expGained,
      credits: creditsGained,
      drops: dropsGained,
    })
  );

  // Restore all weakened parts so player can quick repair
  yield put(restoreAllParts());
  // Reset opponent weakened parts
  yield put(resetOpponentParts());

  yield put(
    addEventLog({
      event: "win_fight",
      eventParams: {
        mobName,
        mobLevel,
        battleRating,
      },
    })
  );
}

function* characterLoseSaga() {
  const {
    data: { fighterData: characterFighter },
  } = yield select(getCharacter);
  const {
    opponent: { slug: mobSlug },
  } = yield select(getFight);
  const { level: mobLevel, name: mobName } = getMobData(mobSlug);

  // Animate character losing
  yield delay(100);
  yield spawn(fightAnimation, characterFighter, "lose");

  // Show fight results
  yield put(setFightStatus("loseResults"));

  yield put(
    addEventLog({
      event: "lose_fight",
      eventParams: {
        mobName,
        mobLevel,
      },
    })
  );
}

function* onwardFightWinSaga() {
  // Reset all fight data
  yield call(resetFightSaga);

  // Enable character movement
  yield put(enableMovement());

  // Show last win dialog from mob
  yield put(setFightStatus("winDialog"));
}

function* endFightWinSaga() {
  // This may not be called, clicking win dialog is optional
  yield put(setFightStatus("notFighting"));
}

function* onwardFightLoseSaga() {
  // Reset all fight data
  yield call(resetFightSaga);

  // Keep character movement locked

  // Show lose dialog from mob
  yield put(setFightStatus("loseDialog"));
}

function* endFightLoseSaga() {
  // After clicking lose dialog, send character back to Bishop City healed
  yield put(setFightStatus("notFighting"));

  // Full heal
  yield call(healSaga);

  // Reset position back to Bishop City and directly to Bishop Shipworks
  const REPAIR_DIALOG =
    "We've fully repaired and restored your starship. It was in such rough, terrible shape that we felt bad charging you money to fix it. So... you're welcome.";
  const shipworksPosition = getBuildingData("bishopShipworks").position;
  yield call(
    enterAreaSaga,
    "bishopCity",
    shipworksPosition,
    "right",
    "bishopShipworks",
    "repair",
    REPAIR_DIALOG
  );
}

function* endFightEscapeSaga() {
  // Turn ship to the left away from opponent
  yield put(turnShip("left"));

  // Move away from opponent
  const {
    opponent: { slug: mobSlug },
  } = yield select(getFight);
  const { position: mobPosition } = getMobData(mobSlug);
  yield put(moveToPosition(mobPosition - ESCAPE_DISTANCE));

  // Reset all fight data
  yield call(resetFightSaga);

  // Enable character movement
  yield put(enableMovement());

  // Set status to not fighting
  yield put(setFightStatus("notFighting"));
}

function* resetFightSaga() {
  // Reset character damage
  yield put(resetDamage());

  // Restore all weakened parts
  yield put(restoreAllParts());

  // Reset skills recharging
  yield put(resetSkillsRecharge());

  // Reset skills values just in case
  yield put(resetSkillsValues());

  // Reset character animations
  yield put(resetAnimations());

  // Reset opponent damage
  yield put(resetOpponentDamage());

  // Reset opponent weakened parts
  yield put(resetOpponentParts());

  // Reset opponent skills recharging
  yield put(resetOpponentSkillsRecharge());

  // Reset opponent skills values just in case
  yield put(resetOpponentSkillsValues());

  // Reset opponent animations
  yield put(resetOpponentAnimations());

  // Reset turn priorities
  yield put(resetTurnPriorities());

  // Reset fight results
  yield put(resetFightResults());

  // Reset combat logs
  yield put(resetCombatLogs());
}

export default function* fightSagas() {
  // Only one fight at a time
  yield takeLeading(startMobDialog, startMobDialogSaga);
  yield takeLeading(previewFight, previewFightSaga);
  yield takeLeading(retreatFight, retreatFightSaga);
  yield takeLeading(startFight, startFightSaga);
  yield takeLeading(continueFight, continueFightSaga);
  yield takeLeading(fightAgain, fightAgainSaga);
  yield takeLeading(onwardFightWin, onwardFightWinSaga);
  yield takeLeading(endFightWin, endFightWinSaga);
  yield takeLeading(onwardFightLose, onwardFightLoseSaga);
  yield takeLeading(endFightLose, endFightLoseSaga);
}
