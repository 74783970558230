import { BASE_STATS } from "data/baseStats";
import { call, delay, put, spawn } from "redux-saga/effects";

import { addCombatLog, showMessage } from "redux/actions";
import { Fighter, SkillModel } from "types";
import {
  getBlastEchoDamage,
  getEagleEyeDamage,
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getHyperShiftDamage,
  getNextConsecutiveTurns,
  getPhantomStrikeDamage,
  getPowerSurgeDamage,
  getPressurePointDamage,
  getRandomBaseStat,
  getRapidFireDamage,
  getReactorOverloadDamage,
  getRechargeTurns,
  getScattershotDamage,
  getShieldBreakerDamage,
  getShieldBypassDamage,
  getShieldRestoreAmount,
  getShieldStormDamage,
  getSkillData,
  getSystemsDecayDamage,
} from "utils/stats";
import { fightAnimation, skillAttackSaga } from "./fight";

export function* shieldBreakerSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(500);

  const { attackDamage, attackWeakenParts } = getShieldBreakerDamage(
    attacker.data.stats
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts,
    BASE_STATS.RESILIENCE
  );
}

export function* scattershotSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(750);

  const randomBaseStat = getRandomBaseStat();

  const { attackDamage, attackWeakenParts } = getScattershotDamage(
    attacker.data.stats
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts,
    randomBaseStat
  );
}

export function* powerSurgeSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(1000);

  yield spawn(fightAnimation, attacker, "attack");
  // Let skill attack hit first before showing defender damage
  yield delay(100);

  const {
    data: { stats, skillsValues },
  } = attacker;

  const { attackDamage, attackWeakenParts, totalDamage } = getPowerSurgeDamage(
    stats,
    defender.data.damage,
    skillsValues
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );

  // Keep track of total damage that's now been used up
  yield put(
    attacker.actions.setSkillValue({ slug: skillSlug, value: totalDamage })
  );
}

export function* rapidFireSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  const { attackDamage, attackWeakenParts } = getRapidFireDamage(
    attacker.data.stats
  );

  const NUMBER_OF_ATTACKS = 4;
  for (var i = 0; i < NUMBER_OF_ATTACKS; i++) {
    if (i > 0) {
      yield delay(500);
    }
    yield spawn(fightAnimation, attacker, "attack");
    // Let skill attack hit first before showing defender damage
    yield delay(100);
    yield call(
      skillAttackSaga,
      skillSlug,
      attacker,
      defender,
      attackDamage,
      attackWeakenParts,
      BASE_STATS.SPEED
    );
  }
}

export function* hyperShiftSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
): any {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(700);

  const nextConsecutiveTurns = getNextConsecutiveTurns(
    attacker.data.turnPriority,
    defender.data.turnPriority,
    defender.data.stats.attackSpeed
  );

  const { attackDamage, attackWeakenParts } = getHyperShiftDamage(
    attacker.data.stats,
    nextConsecutiveTurns
  );

  // Add defender enough attack speed to turn priority so that it's their turn next
  // This is before the attack, in case their attack speed gets affected
  yield put(
    defender.actions.addTurnPriority(
      nextConsecutiveTurns * defender.data.stats.attackSpeed
    )
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );
}

export function* phantomStrikeSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
): any {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(300);

  yield spawn(fightAnimation, attacker, "attack");
  // Let skill attack hit first before showing defender damage
  yield delay(100);

  const { attackDamage, attackWeakenParts } = getPhantomStrikeDamage(
    attacker.data.stats
  );

  // Subtract defender attack speed to mimic this turn never happening
  yield put(defender.actions.addTurnPriority(-defender.data.stats.attackSpeed));

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );
}

export function* repairSaga(
  attacker: Fighter,
  _defender: Fighter,
  skillSlug: string
) {
  const { healAmount } = getEmergencyRepairHealth(attacker.data.stats);

  yield call(attacker.actions.healSaga, healAmount);

  yield spawn(skillAnimation, attacker, skillSlug);

  const skillInfo = getSkillData(skillSlug);
  yield put(
    addCombatLog({
      type: "heal",
      fighter: attacker.name,
      message: `${attacker.name} used ${skillInfo.name}, repairing ship health by <strong>${healAmount}</strong>`,
    })
  );
}

export function* shieldRestoreSaga(
  attacker: Fighter,
  _defender: Fighter,
  skillSlug: string
) {
  const {
    data: { stats, totalBaseStats },
  } = attacker;

  const { restoreAmount } = getShieldRestoreAmount(stats, totalBaseStats);

  yield put(
    attacker.actions.restorePart({
      baseStatRestored: BASE_STATS.RESILIENCE,
      restorePartAmount: restoreAmount,
    })
  );

  yield spawn(skillAnimation, attacker, skillSlug);

  const skillInfo = getSkillData(skillSlug);
  yield put(
    addCombatLog({
      type: "heal",
      fighter: attacker.name,
      message: `${attacker.name} used ${skillInfo.name}, restoring resilience by <strong>${restoreAmount}</strong>`,
    })
  );
}

export function* shieldStormSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(500);

  const {
    data: { stats, damage, skillsValues },
  } = attacker;

  const { attackDamage, attackWeakenParts, totalDamageReduced } =
    getShieldStormDamage(stats, damage, skillsValues);

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );

  // Keep track of total damage reduced that's now been used up
  yield put(
    attacker.actions.setSkillValue({
      slug: skillSlug,
      value: totalDamageReduced,
    })
  );
}

export function* energyBlastSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(600);

  const {
    data: { stats, currentBaseStats },
  } = attacker;

  const { attackDamage, attackWeakenParts } = getEnergyBlastDamage(
    stats,
    currentBaseStats
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );
}

export function* reactorOverloadSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(600);

  const {
    data: { stats, currentBaseStats, totalBaseStats },
  } = attacker;

  const { attackDamage, attackWeakenParts } = getReactorOverloadDamage(
    stats,
    currentBaseStats
  );

  // Drain antimatter of character
  yield put(
    attacker.actions.weakenPartFully({
      baseStatWeakened: BASE_STATS.ENERGY,
      totalBaseStats,
    })
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );
}

export function* shieldBypassSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(400);

  const {
    data: { stats, currentBaseStats },
  } = attacker;

  const { attackDamage, attackWeakenParts } = getShieldBypassDamage(
    stats,
    currentBaseStats
  );

  // Ignore shields
  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts,
    undefined,
    false,
    true
  );
}

export function* systemsDecaySaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);

  const { attackDamage, attackWeakenParts } = getSystemsDecayDamage(
    attacker.data.stats
  );

  for (const key in BASE_STATS) {
    yield delay(600);

    const baseStatWeakened = BASE_STATS[key];

    yield call(
      skillAttackSaga,
      skillSlug,
      attacker,
      defender,
      attackDamage,
      attackWeakenParts,
      baseStatWeakened,
      true
    );
  }
}

export function* skillsRechargeSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);

  yield delay(1000);

  yield put(attacker.actions.resetSkillsRecharge());

  const skillInfo = getSkillData(skillSlug);
  yield put(
    addCombatLog({
      type: "heal",
      fighter: attacker.name,
      message: `${attacker.name} used ${skillInfo.name}, instantly recharging all skills`,
    })
  );

  yield delay(300);
}

export function* pressurePointSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  yield spawn(fightAnimation, attacker, "attack");
  // Let skill attack hit first before showing defender damage
  yield delay(100);

  const {
    data: { stats, skillsValues },
  } = attacker;

  const { attackDamage, attackWeakenParts } = getPressurePointDamage(
    stats,
    skillsValues
  );

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts
  );

  // Keep track of how many time this skill is used
  const useCount = skillsValues[skillSlug] || 0;
  const newUseCount = useCount + 1;
  yield put(
    attacker.actions.setSkillValue({ slug: skillSlug, value: newUseCount })
  );
}

export function* eagleEyeSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  yield spawn(fightAnimation, attacker, "attack");
  // Let skill attack hit first before showing defender damage
  yield delay(100);

  const { attackDamage, attackWeakenParts } = getEagleEyeDamage(
    attacker.data.stats
  );

  // Ignore dodge
  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts,
    BASE_STATS.PRECISION,
    true,
    false,
    true
  );
}

export function* blastEchoSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  yield spawn(skillAnimation, attacker, skillSlug);
  // Let skill attack hit first before showing defender damage
  yield delay(600);

  const { attackDamage, attackWeakenParts, lastWeakenedBaseStat } =
    getBlastEchoDamage(attacker.data.stats, defender.data.damage);

  yield call(
    skillAttackSaga,
    skillSlug,
    attacker,
    defender,
    attackDamage,
    attackWeakenParts,
    lastWeakenedBaseStat || undefined
  );
}

export function* activateSkillSaga(
  attacker: Fighter,
  defender: Fighter,
  skillSlug: string
) {
  try {
    const skill = attacker.data.skills.find(
      (skill: SkillModel) => skill.slug === skillSlug
    );
    if (!skill) {
      throw new Error("Skill not found");
    }

    // Check if skill is still recharging
    const rechargeTurns = attacker.data.skillsRecharge[skillSlug];
    if (typeof rechargeTurns !== "undefined" && rechargeTurns > 0) {
      yield put(showMessage("Skill is still recharging"));
      return;
    }

    // Use skill by calling its saga and value
    // Call saga directly instead of yield putting action to keep it synchronous
    yield call(skill.saga, attacker, defender, skillSlug);

    // If activated successfully, start recharging skill + 1 to accommodate current turn
    const newRechargeTurns = getRechargeTurns(skill.recharge) + 1;
    yield put(
      attacker.actions.setSkillRecharge({
        slug: skillSlug,
        turns: newRechargeTurns,
      })
    );
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* skillAnimation(fighter: Fighter, animation: string) {
  yield put(fighter.actions.startAnimation(animation));

  yield delay(1000);

  // Reset animation
  yield put(fighter.actions.endAnimation(animation));
}

export default function* skillSagas() {}
