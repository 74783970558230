import energyImage from "assets/stat-energy.svg";
import firepowerImage from "assets/stat-firepower.svg";
import precisionImage from "assets/stat-precision.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import { CharacterBaseStats } from "types";
import { COLORS } from "utils/constants";

export const BASE_STATS: Record<string, keyof CharacterBaseStats> = {
  FIREPOWER: "firepower",
  RESILIENCE: "resilience",
  SPEED: "speed",
  PRECISION: "precision",
  ENERGY: "energy",
};

export interface BaseStatInfo {
  slug: keyof CharacterBaseStats;
  name: string;
  color: string;
  image: string;
  description: string;
  part: string;
}

export const BASE_STATS_INFO: Record<keyof CharacterBaseStats, BaseStatInfo> = {
  firepower: {
    slug: BASE_STATS.FIREPOWER,
    name: "firepower",
    color: COLORS.FIREPOWER,
    image: firepowerImage,
    description:
      "Firepower determines a ship's potential for more powerful attacks.",
    part: "weapons",
  },
  resilience: {
    slug: BASE_STATS.RESILIENCE,
    name: "resilience",
    color: COLORS.RESILIENCE,
    image: resilienceImage,
    description:
      "Resilience determines how strong a ship's shields and health are.",
    part: "shields",
  },
  speed: {
    slug: BASE_STATS.SPEED,
    name: "speed",
    color: COLORS.SPEED,
    image: speedImage,
    description:
      "Speed determines how often a ship can attack and dodge, and how fast it moves.",
    part: "thrusters",
  },
  precision: {
    slug: BASE_STATS.PRECISION,
    name: "precision",
    color: COLORS.PRECISION,
    image: precisionImage,
    description:
      "Precision determines how consistently strong a ship's attacks are and how often it'll land a critical hit.",
    part: "targeting grid",
  },
  energy: {
    slug: BASE_STATS.ENERGY,
    name: "antimatter",
    color: COLORS.ENERGY,
    image: energyImage,
    description:
      "Antimatter determines the frequency and power of a pilot's special abilities.",
    part: "reactor core",
  },
};
