const PROD_HOST = "lightvoyagers.com";

export const ENV = {
  DEV: "development",
  PROD: "production",
};

export const getEnvironment = () => {
  const isDev = window.location.hostname.toLowerCase().search(PROD_HOST) < 0;
  return isDev ? ENV.DEV : ENV.PROD;
};
