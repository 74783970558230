import React from "react";
import { useSelector } from "react-redux";
import { getGameState } from "redux/selectors";
import styled, { keyframes } from "styled-components";

const messageAnimation = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const MessagesOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  z-index: 15;
  pointer-events: none;
  font-weight: 600;
  text-transform: uppercase;
`;

const Message = styled.div`
  animation: ${messageAnimation} 4s linear forwards;
  padding: 15px 20px;
  border: 1px white solid;
  background-color: #000000;
  opacity: 0.95;
`;

export default function Messages() {
  const { messages } = useSelector(getGameState);

  return (
    <MessagesOverlay>
      {messages.map((message, index) => {
        // Render only latest message
        if (messages.length - 1 === index) {
          return <Message key={`${message}.${index}`}>{message}</Message>;
        }
        return null;
      })}
    </MessagesOverlay>
  );
}
