import { AreaModel, PlanetModel } from "types";

import bgFixedImageLaunchCheck from "assets/bg-fixed-launch-check.svg";
import bgSlowImageLaunchCheck from "assets/bg-slow-launch-check.svg";
import bgMovingImageLaunchCheck from "assets/bg-moving-launch-check.svg";
import fgImageLaunchCheck from "assets/fg-launch-check.svg";
import bgFixedImageBishopCity from "assets/bg-fixed-meteora.svg";
import bgSlowImageBishopCity from "assets/bg-slow-meteora.svg";
import bgMovingImageBishopCity from "assets/bg-moving-meteora.svg";
import fgImageBishopCity from "assets/fg-meteora.svg";
import bgFixedImageHollowlands from "assets/bg-fixed-hollowlands.svg";
import bgSlowImageHollowlands from "assets/bg-slow-hollowlands.svg";
import bgMovingImageHollowlands from "assets/bg-moving-hollowlands.svg";
import fgImageHollowlands from "assets/fg-hollowlands.svg";
import bgFixedImageStraylightEnclave from "assets/bg-fixed-straylight-enclave.svg";
import bgSlowImageStraylightEnclave from "assets/bg-slow-straylight-enclave.svg";
import bgMovingImageStraylightEnclave from "assets/bg-moving-straylight-enclave.svg";
import bgFixedImageSenecaPrime from "assets/bg-fixed-seneca-prime.svg";
import bgSlowImageSenecaPrime from "assets/bg-slow-seneca-prime.svg";
import fgImageStraylightEnclave from "assets/fg-straylight-enclave.svg";
import bgShipTransporterImage from "assets/bg-ship-transporter.svg";
import bgShipTaxiImage from "assets/bg-ship-taxi.svg";
import bgShipSpeederImage from "assets/bg-ship-speeder.svg";
import bgShipTramImage from "assets/bg-ship-tram.svg";
import bgShipVanImage from "assets/bg-ship-van.svg";
import bgShipTransporterRedImage from "assets/bg-ship-transporter-red.svg";
import bgShipTaxiRedImage from "assets/bg-ship-taxi-red.svg";
import bgShipSpeederRedImage from "assets/bg-ship-speeder-red.svg";
import bgShipTransporterWhiteImage from "assets/bg-ship-transporter-white.svg";
import bgShipTaxiWhiteImage from "assets/bg-ship-taxi-white.svg";
import bgShipSpeederWhiteImage from "assets/bg-ship-speeder-white.svg";

interface PlanetData {
  [key: string]: PlanetModel;
}

interface AreaData {
  [key: string]: AreaModel;
}

export const PLANETS: PlanetData = {
  bishop: {
    slug: "bishop",
    name: "Bishop",
    mainArea: "bishopCity",
    playlist: "bishop",
  },
  seneca: {
    slug: "seneca",
    name: "Seneca",
    mainArea: "senecaPrime",
    playlist: "seneca",
  },
};

export const AREAS: AreaData = {
  launchCheck: {
    slug: "launchCheck",
    name: "Launch Check",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageLaunchCheck,
    backgroundSlowImage: bgSlowImageLaunchCheck,
    backgroundMovingImage: bgMovingImageLaunchCheck,
    foregroundImage: fgImageLaunchCheck,
    backgroundFixedShips: [],
    backgroundSlowShips: [],
    backgroundMovingShips: [],
    npcs: ["rook"],
    mobs: ["conway"],
    buildings: [],
    moveRange: 80,
    areaLeft: null,
    areaRight: "bishopCity",
  },
  bishopCity: {
    slug: "bishopCity",
    name: "Bishop City",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageBishopCity,
    backgroundSlowImage: bgSlowImageBishopCity,
    backgroundMovingImage: bgMovingImageBishopCity,
    foregroundImage: fgImageBishopCity,
    backgroundFixedShips: [
      {
        image: bgShipTramImage,
        duration: 15,
        height: 6,
        verticalPosition: 18,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipVanImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: false,
      },
      {
        image: bgShipTramImage,
        duration: 35,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederImage,
        duration: 25,
        height: 10,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipTramImage,
        duration: 45,
        height: 10,
        verticalPosition: 50,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTramImage,
        duration: 25,
        height: 18,
        verticalPosition: 50,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederImage,
        duration: 15,
        height: 18,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipTaxiImage,
        duration: 20,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterImage,
        duration: 22,
        height: 18,
        verticalPosition: 15,
        isMovingRight: false,
      },
      {
        image: bgShipVanImage,
        duration: 18,
        height: 18,
        verticalPosition: 80,
        isMovingRight: true,
      },
    ],
    npcs: ["wick"],
    mobs: [],
    buildings: ["bishopShipworks", "bishopPilotAcademy"],
    moveRange: 180,
    areaLeft: "launchCheck",
    areaRight: "hollowlands",
  },
  hollowlands: {
    slug: "hollowlands",
    name: "Hollowlands",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageHollowlands,
    backgroundSlowImage: bgSlowImageHollowlands,
    backgroundMovingImage: bgMovingImageHollowlands,
    foregroundImage: fgImageHollowlands,
    backgroundFixedShips: [
      {
        image: bgShipSpeederRedImage,
        duration: 10,
        height: 6,
        verticalPosition: 30,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterRedImage,
        duration: 16,
        height: 6,
        verticalPosition: 25,
        isMovingRight: true,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiRedImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterRedImage,
        duration: 45,
        height: 10,
        verticalPosition: 15,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterRedImage,
        duration: 22,
        height: 18,
        verticalPosition: 25,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederRedImage,
        duration: 14,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTaxiRedImage,
        duration: 20,
        height: 18,
        verticalPosition: 50,
        isMovingRight: false,
      },
    ],
    npcs: ["witt"],
    mobs: ["gren", "ardin", "sharper", "cross", "kether", "gamma", "lockheed"],
    buildings: [],
    moveRange: 250,
    areaLeft: "bishopCity",
    areaRight: "straylightEnclave",
  },
  straylightEnclave: {
    slug: "straylightEnclave",
    name: "Straylight Compound",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageStraylightEnclave,
    backgroundSlowImage: bgSlowImageStraylightEnclave,
    backgroundMovingImage: bgMovingImageStraylightEnclave,
    foregroundImage: fgImageStraylightEnclave,
    backgroundFixedShips: [
      {
        image: bgShipSpeederWhiteImage,
        duration: 12,
        height: 6,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 18,
        height: 6,
        verticalPosition: 35,
        isMovingRight: true,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiWhiteImage,
        duration: 40,
        height: 10,
        verticalPosition: 35,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 45,
        height: 10,
        verticalPosition: 55,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterWhiteImage,
        duration: 21,
        height: 18,
        verticalPosition: 15,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederWhiteImage,
        duration: 14,
        height: 18,
        verticalPosition: 40,
        isMovingRight: false,
      },
      {
        image: bgShipTaxiWhiteImage,
        duration: 20,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: [],
    mobs: ["rincon", "hadron", "acela", "megama", "breeden", "revin", "valera"],
    buildings: [],
    moveRange: 250,
    areaLeft: "hollowlands",
    areaRight: null,
  },
  senecaPrime: {
    slug: "senecaPrime",
    name: "Seneca Prime",
    planet: "seneca",
    backgroundFixedImage: bgFixedImageSenecaPrime,
    backgroundSlowImage: bgSlowImageSenecaPrime,
    backgroundMovingImage: bgMovingImageStraylightEnclave,
    foregroundImage: fgImageStraylightEnclave,
    backgroundFixedShips: [],
    backgroundSlowShips: [],
    backgroundMovingShips: [],
    npcs: [],
    mobs: [],
    buildings: [],
    moveRange: 300,
    areaLeft: null,
    areaRight: null,
  },
};
