import BaseStats from "components/base/BaseStats";
import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import DerivedStats from "components/base/DerivedStats";
import { CharacterBaseStats } from "types";
import { useImmer } from "use-immer";

interface EnhanceProps {
  canEnhance: boolean;
}

interface EnhanceState {
  highlightStat: keyof CharacterBaseStats | null;
}

export default function ShipworksEnhance({ canEnhance = false }: EnhanceProps) {
  const initialEnhanceState: EnhanceState = {
    highlightStat: null,
  };
  const [state, setState] = useImmer<EnhanceState>(initialEnhanceState);

  const setHighlightStat = (stat: keyof CharacterBaseStats) => {
    setState((draft) => {
      draft.highlightStat = stat;
    });
  };

  const resetHighlightStat = () => {
    setState((draft) => {
      draft.highlightStat = null;
    });
  };

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Core Attributes</SectionTitle>
        <SectionContentScroll>
          <BaseStats
            canEnhance={canEnhance}
            onSetHighlightStat={setHighlightStat}
            onResetHighlightStat={resetHighlightStat}
          />
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Enhanced Stats</SectionTitle>
        <SectionContentScroll>
          <DerivedStats highlightStat={state.highlightStat} />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
