import styled, { css } from "styled-components";
import { COLORS } from "utils/constants";

interface TooltipProps {
  isAbove?: boolean;
  isLeft?: boolean;
  children: React.ReactNode;
}

interface BoxProps {
  isAbove: boolean;
  isLeft: boolean;
}

interface GradeProps {
  color: string;
}

interface RequirementProps {
  color: string;
}

interface BarProps {
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface IconProps {
  image: string;
}

interface ValuesProps {
  color: string;
}

const aboveMixin = css`
  transform: translate(0, -5px);
  top: auto;
  bottom: 100%;
`;

const leftMixin = css`
  right: 0;
`;

const TooltipBox = styled.div<BoxProps>`
  position: absolute;
  padding: 15px;
  background-color: #000000;
  border: 1px #ffffff50 solid;
  width: 250px;
  font-size: 14px;
  pointer-events: none;
  z-index: 5;
  opacity: 0;
  transition: opacity 200ms ease-out;

  transform: translate(0, 5px);
  top: 100%;

  ${(props) => props.isAbove && aboveMixin}
  ${(props) => props.isLeft && leftMixin}
`;

export const Trigger = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;

  &:hover ${TooltipBox} {
    opacity: 0.95;
  }
`;

export const TooltipName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
`;

export const TooltipType = styled.div`
  color: #fff648;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
`;

export const TooltipGrade = styled.div<GradeProps>`
  color: ${(props) => props.color};
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: -2px;
`;

export const TooltipRequirements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 4px;
`;

export const TooltipRequirement = styled.div<RequirementProps>`
  color: ${(props) => props.color};
  white-space: nowrap;
  text-transform: capitalize;
  font-style: italic;
`;

export const TooltipBar = styled.div<BarProps>`
  margin-top: 4px;
  transform: skew(-30deg);
  background-color: ${(props) => props.color}50;
  height: 3px;
  width: 100%;
  overflow: hidden;
`;

export const TooltipBarValue = styled.div.attrs(
  ({ percentage }: BarValueProps) => ({
    style: {
      width: `${percentage}%`,
    },
  })
)<BarValueProps>`
  height: 100%;
  background-color: ${(props) => props.color};
  transition: all 200ms ease-out;
`;

export const TooltipDesc = styled.div`
  margin-top: 10px;
  color: #ddd;
`;

export const TooltipInfo = styled.div`
  margin-top: 10px;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

export const TooltipStat = styled(Info)`
  color: ${COLORS.STATS};
`;

export const TooltipRecharge = styled(Info)`
  color: ${COLORS.ENERGY};
`;

export const TooltipCredits = styled(Info)`
  color: ${COLORS.CREDITS};
  margin-top: 5px;
`;

export const TooltipValues = styled(Info)<ValuesProps>`
  color: ${(props) => props.color};
`;

export const TotalValue = styled.span`
  text-decoration: line-through;
  opacity: 0.75;
`;

export const TooltipRanges = styled(Info)``;

const Icon = styled.div<IconProps>`
  height: 12px;
  width: 12px;
  mask: url(${(props) => props.image}) no-repeat center;
`;

export const StatIcon = styled(Icon)`
  background-color: ${COLORS.STATS};
`;

export const RechargeIcon = styled(Icon)`
  background-color: ${COLORS.ENERGY};
`;

export const CreditsIcon = styled(Icon)`
  background-color: ${COLORS.CREDITS};
`;

export const ValuesIcon = styled(Icon)<ValuesProps>`
  background-color: ${(props) => props.color};
`;

export const RangesIcon = styled(Icon)``;

const Tooltip = ({
  isAbove = false,
  isLeft = false,
  children,
}: TooltipProps) => {
  return (
    <TooltipBox isAbove={isAbove} isLeft={isLeft}>
      {children}
    </TooltipBox>
  );
};

export default Tooltip;
