import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import {
  floatAnimation,
  upgradesScreenAnimation,
} from "components/styles/animations";
import { BASE_STATS_INFO } from "data/baseStats";
import { getShipUpgrades } from "redux/selectors";
import styled, { css } from "styled-components";
import { CharacterBaseStats, ShipTypes } from "types";
import { getShipData } from "utils/stats";
import AvatarShip from "../ui/AvatarShip";

interface ShipCardProps {
  shipSlug: ShipTypes;
  isLarge?: boolean;
  isSelected: boolean;
  onClick?: (ship: ShipTypes) => void;
}

interface ShipWrapperProps {
  $color: string;
  $bgTime: number;
  $isSelected: boolean;
}

interface ShipProps {
  $floatTime: number;
}

interface ShipDescProps {
  $isLarge: boolean;
}

interface StatBarProps {
  $isLarge: boolean;
}

interface BarIconProps {
  image: string;
  color: string;
  $isLarge: boolean;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

const hoverMixin = css<ShipWrapperProps>`
  background-color: ${(props) => props.$color}20;
  border-color: ${(props) => props.$color};
`;

const selectedMixin = css<ShipWrapperProps>`
  background-color: ${(props) => props.$color}20;
  border-color: ${(props) => props.$color};
  border-width: 4px;
  margin: -3px;
`;

const ShipWrapper = styled.div<ShipWrapperProps>`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;
  background-color: #00000099;
  color: ${(props) => props.$color};
  background-size: 2em 2em;
  background-position: center center;
  background-image: linear-gradient(to right, #aaaaaa30 1px, transparent 1px),
    linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${upgradesScreenAnimation} ${(props) => `${props.$bgTime}s`} linear
    infinite;
  border: 1px ${(props) => props.$color}90 solid;

  :hover {
    ${hoverMixin}
  }

  ${(props) => props.$isSelected && selectedMixin}
`;

const Ship = styled.div<ShipProps>`
  animation: ${floatAnimation} ${(props) => `${props.$floatTime}s`} ease-in-out
    infinite;
  position: relative;
  overflow: hidden;
  padding: 20px;
  padding-bottom: 10px;
  width: 60%;
`;

const ShipInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 20px;
  padding-top: 0;
`;

const ShipName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: center;
`;

const ShipDesc = styled.div<ShipDescProps>`
  color: white;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: left;

  ${(props) => props.$isLarge && `font-size: 16px;`}
`;

const ShipStats = styled.div`
  color: white;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;

const StatsTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 5px;
  color: #2da5b2;
`;

const StatWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StatIcon = styled.div<BarIconProps>`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;

  ${(props) => props.$isLarge && `height: 16px; width: 16px;`}
`;

const StatBar = styled.div<StatBarProps>`
  height: 14px;
  transform: skew(-30deg);
  flex: 1 1;

  ${(props) => props.$isLarge && `height: 20px;`}
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  height: 100%;
  z-index: 2;
  background-color: ${(props) => props.color};
`;

const STAT_ADJUST = 0.2;
// Add a tiny bit to stat bars to make the lowest value visible
const DEFAULT_FLOAT_TIME = 4;
// Animation timing for different float speeds for each ship
const DEFAULT_BG_TIME = 1;

export default function ShipCard({
  shipSlug,
  isLarge = false,
  isSelected,
  onClick,
}: ShipCardProps) {
  const shipInfo = getShipData(shipSlug);

  const defaultShipData = {
    ship: shipSlug,
    shipUpgrades: getShipUpgrades(),
    ui: { animation: null, isMoving: false },
  };

  const maxStatValue =
    Math.max(...Object.values(shipInfo.startingBaseStats)) + STAT_ADJUST;

  const floatTime = DEFAULT_FLOAT_TIME - shipInfo.startingBaseStats.speed / 2;
  const bgTime = DEFAULT_BG_TIME - shipInfo.startingBaseStats.speed / 5;

  return (
    <ShipWrapper
      key={shipSlug}
      $color={shipInfo.color}
      $bgTime={bgTime}
      $isSelected={isSelected}
      onClick={() => onClick && onClick(shipSlug)}
    >
      <Ship $floatTime={floatTime}>
        <AvatarShip highlightPart={null} shipData={defaultShipData} />
      </Ship>
      <ShipInfo>
        <ShipName>{shipInfo.name}</ShipName>
        <ShipDesc $isLarge={isLarge}>{shipInfo.description}</ShipDesc>
        <ShipStats>
          <StatsTitle>Base Stats</StatsTitle>
          {Object.values(BASE_STATS_INFO).map((stat) => {
            const statSlug = stat.slug as keyof CharacterBaseStats;
            const statValue =
              shipInfo.startingBaseStats[statSlug] + STAT_ADJUST;

            return (
              <Trigger key={statSlug}>
                <StatWrapper>
                  <StatIcon
                    image={stat.image}
                    color={stat.color}
                    $isLarge={isLarge}
                  />
                  <StatBar $isLarge={isLarge}>
                    <BarValue
                      percentage={(statValue / maxStatValue) * 100}
                      color={stat.color}
                    ></BarValue>
                  </StatBar>
                </StatWrapper>
                <Tooltip isAbove>
                  <TooltipName>{stat.name}</TooltipName>
                  <TooltipDesc>{stat.description}</TooltipDesc>
                </Tooltip>
              </Trigger>
            );
          })}
        </ShipStats>
      </ShipInfo>
    </ShipWrapper>
  );
}
