import {
  DataWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import InstalledUpgrades from "components/base/InstalledUpgrades";
import Inventory from "components/base/Inventory";

interface UpgradeProps {
  canInstall: boolean;
}

export default function UpgradeScreen({ canInstall = false }: UpgradeProps) {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Installed Upgrades</SectionTitle>
        <SectionContent>
          <InstalledUpgrades canInstall={!!canInstall} />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Upgrade Inventory</SectionTitle>
        <SectionContentScroll>
          <Inventory isUpgrades canAction={!!canInstall} />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
