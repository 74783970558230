import React from "react";
import styled from "styled-components";

import Dialog from "./Dialog";

interface DialogsProps {
  avatar: string;
  currentStep: number;
  currentDialog: string;
  hasAction: boolean;
}

const DialogWrapper = styled.div``;

export default function Dialogs({
  avatar,
  currentStep,
  currentDialog,
  hasAction,
}: DialogsProps) {
  return currentStep > 0 ? (
    <DialogWrapper>
      <Dialog
        avatar={avatar}
        dialogText={currentDialog}
        hasAction={hasAction}
      />
    </DialogWrapper>
  ) : null;
}
