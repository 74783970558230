import { CharacterBaseStats, CharacterDerivedStats, StatRanges } from "types";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import { BASE_STATS } from "./baseStats";
import { COLORS } from "utils/constants";
import {
  roundWhole,
  roundTwoDecimals,
  formatNumber,
  formatMultiplier,
  formatAddNumber,
  formatAddMultiplier,
  formatAddPercentage,
  formatRange,
  formatAddRange,
} from "utils/formatters";

export interface DerivedStatInfo {
  name: string;
  description: string;
  baseStat: keyof CharacterBaseStats;
  startingValue: number;
  incrementValue: number;
  image: string;
  color: string;
  rounder: (x: number) => number;
  formatter: (x: number) => string;
  addFormatter: (x: number) => string;
}

export const DERIVED_STATS: Record<
  keyof CharacterDerivedStats,
  DerivedStatInfo
> = {
  maxHealth: {
    name: "Max Ship Health",
    description: "How much overall health your ship has.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 50,
    incrementValue: 30,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  maxAttackDamage: {
    name: "Max Attack Damage",
    description: "The maximum ship damage you'll deal in regular attacks.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 10,
    incrementValue: 3,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  minAttackDamage: {
    name: "Min Attack Damage",
    description:
      "The minimum ship damage you'll deal in regular attacks. This value can't exceed maximum damage",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 2,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  attackSpeed: {
    name: "Attack Speed",
    description:
      "This stat determines how frequently you can take actions (like attacking, performing skills, or using supplies) in battle.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 10,
    incrementValue: 1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  attackAccuracy: {
    name: "Attack Accuracy",
    description:
      "The higher your accuracy compared to the defender's evasion, the greater the chance of dealing higher damage or landing a critical hit.",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 10,
    incrementValue: 1,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  maxWeakenParts: {
    name: "Max Weaken Parts",
    description:
      "The max amount you can weaken an opponent's specific part with each attack, which degrades their stats during battle.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 1,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  minWeakenParts: {
    name: "Min Weaken Parts",
    description:
      "The minimum amount you can weaken an opponent's specific part with each attack, which degrades their stats during battle. This value can't exceed maximum weaken parts",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 0.05,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  damageReduction: {
    name: "Damage Reduction",
    description:
      "When you're hit by an opponent, the damage received is reduced by this amount.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 2,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  weakenPartsReduction: {
    name: "Weaken Parts Reduction",
    description:
      "When your ship parts are targeted by an opponent, how much your ship parts are weakened is reduced by this amount.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 0.05,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  criticalDamageBonus: {
    name: "Critical Damage Bonus",
    description:
      "Increases the damage you deal when you land a critical hit by this amount.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 10,
    incrementValue: 3,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundWhole,
    formatter: formatAddNumber,
    addFormatter: formatAddNumber,
  },
  criticalWeakenBonus: {
    name: "Critical Weaken Bonus",
    description:
      "Increases the weaken parts you deal when you land a critical hit by this amount.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 1,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: formatAddNumber,
    addFormatter: formatAddNumber,
  },
  attackEvasion: {
    name: "Attack Evasion",
    description:
      "The higher your evasion compared to the attacker's accuracy, the greater the chance of taking lower damage or fully dodging the attack.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 10,
    incrementValue: 1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  movementSpeed: {
    name: "Movement Speed",
    description:
      "How quickly your ship can move around the planets, and your likelihood of successfully escaping a battle.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 1,
    incrementValue: 0.1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: formatMultiplier,
    addFormatter: formatAddMultiplier,
  },
  fasterRecharge: {
    name: "Faster Ability Recharge",
    description:
      "Your active pilot skills recharge faster during battle, the number of turns to recharge reduced by this value.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 0,
    incrementValue: 0.1,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  energyMultiplier: {
    name: "Antimatter Boost",
    description: "Increases the efficacy of your pilot skills.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 0,
    incrementValue: 0.05,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: formatAddPercentage,
    addFormatter: formatAddPercentage,
  },
};

export interface StatRangeInfo {
  name: string;
  description: string;
  baseStats: (keyof CharacterBaseStats)[];
  images: string[];
  colors: string[];
  rounder: (x: number) => number;
  formatter: (x: number, y: number) => string;
  addFormatter: (x: number, y: number) => string;
}

export const STAT_RANGES: Record<keyof StatRanges, StatRangeInfo> = {
  attackDamageRange: {
    name: "Attack Damage Range",
    description:
      "The range of damage you'll deal in a regular attack. Your Firepower determines max damage. Your Precision determines min damage.",
    baseStats: [BASE_STATS.FIREPOWER, BASE_STATS.PRECISION],
    images: [firepowerImage, precisionImage],
    colors: [COLORS.FIREPOWER, COLORS.PRECISION],
    rounder: roundWhole,
    formatter: formatRange,
    addFormatter: formatAddRange,
  },
  weakenPartsRange: {
    name: "Weaken Parts Range",
    description:
      "The range of weaken parts you'll deal in a regular attack. Your Firepower determines max weaken parts. Your Precision determines min weaken parts.",
    baseStats: [BASE_STATS.FIREPOWER, BASE_STATS.PRECISION],
    images: [firepowerImage, precisionImage],
    colors: [COLORS.FIREPOWER, COLORS.PRECISION],
    rounder: roundTwoDecimals,
    formatter: formatRange,
    addFormatter: formatAddRange,
  },
};

export type DerivedStatDisplayModel =
  | {
      isRange?: true;
      slug: keyof StatRanges;
    }
  | {
      isRange?: false;
      slug: keyof CharacterDerivedStats;
    };

export interface DerivedStatsGroupModel {
  name: string;
  stats: DerivedStatDisplayModel[];
}

export const DERIVED_STATS_GROUPS: Record<string, DerivedStatsGroupModel> = {
  attack: {
    name: "Attack",
    stats: [
      {
        slug: "attackDamageRange",
        isRange: true,
      },
      {
        slug: "weakenPartsRange",
        isRange: true,
      },
      { slug: "attackAccuracy" },
      { slug: "attackSpeed" },
      { slug: "criticalDamageBonus" },
      { slug: "criticalWeakenBonus" },
    ],
  },
  defense: {
    name: "Defense",
    stats: [
      { slug: "maxHealth" },
      { slug: "damageReduction" },
      { slug: "weakenPartsReduction" },
      { slug: "attackEvasion" },
    ],
  },
  abilities: {
    name: "Pilot Abilities",
    stats: [{ slug: "fasterRecharge" }, { slug: "energyMultiplier" }],
  },
  movement: {
    name: "Movement",
    stats: [{ slug: "movementSpeed" }],
  },
};
