import styled from "styled-components";

import creditsImage from "assets/credits.svg";

export const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  cursor: pointer;

  &:hover ${ActionWrapper} {
    opacity: 1;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ActionButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${creditsImage}) no-repeat center;
  mask-size: 60%;
`;

export const ItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-size: 14px;
`;
