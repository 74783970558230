import { css, FlattenSimpleInterpolation, keyframes } from "styled-components";
import { COLORS } from "utils/constants";

// Skill Animations

export const rapidFireAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(.2) rotate(0deg);
  }

  10% {
    opacity: 1;
  }

  80% {
    opacity: 1;
    transform: scale(.75) rotate(2880deg);
  }

  100% {
    opacity: 0;
    transform: scale(0) rotate(4320deg);
  }
`;

export const energyBlastAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scale(.5);
  }

  80% {
    opacity: .8;
    filter: saturate(150%) brightness(150%);
  }

  100% {
    opacity: 0;
    transform: scale(10);
    border-width: 10px;
  }
`;

export const reactorOverloadAnimation = keyframes`
  0% {
    opacity: 0;
    background-color: ${COLORS.ENERGY};
    transform: scale(.5);
  }  

  20% {
    opacity: 0;
  }

  30% {
    transform: scale(0.5);
    filter: saturate(150%) brightness(150%);
    background-color: ${COLORS.ENERGY};
  }

  90% {
    opacity: 1;
    background-color: #ffffff;
  }

  100% {
    opacity: 0;
    transform: scale(16);
    filter: saturate(150%) brightness(150%);
  }
`;

export const shieldBreakerAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(1) scaleY(0);
    background-color: ${COLORS.FIREPOWER}30;
    box-shadow: 0 0px 20px ${COLORS.FIREPOWER};
  }

  20% {
    opacity: .8;
    transform: scaleX(10) scaleY(.5);
  }

  90% {
    opacity: 1;
    transform: scaleX(10) scaleY(.2);
    background-color: ${COLORS.FIREPOWER}70;
    box-shadow: 0 0px 200px ${COLORS.FIREPOWER};
    border-width: 20px;
  }

  100% {
    opacity: 0;
    transform: scaleX(10) scaleY(0);
  }
`;

export const scattershotAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0;
    border-width: 1px;
  }

  10% {
    opacity: 0.8;
  }

  50% {
    transform: scale(1) rotate(180deg);
  }

  90% {
    opacity: 0.8;
  }

  100% {
    transform: scale(10) rotate(540deg);
    opacity: 0;
    border-width: 20px;
  }
`;

export const powerSurgeAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(0);
    border: .5px ${COLORS.FIREPOWER}20 solid;
    border-radius: 50%;
    background-color: ${COLORS.FIREPOWER}80;
  }

  20% {
    transform: rotate(360deg) scale(0.8);
    border-radius: 30%;
  }

  40% {
    transform: rotate(720deg) scale(1.5);
    border-radius: 40%;
  }

  60% {
    transform: rotate(900deg) scale(1.2);
    border-radius: 35%;
  }

  90% {
    transform: rotate(1440deg) scale(2);
    border-radius: 45%;
  }

  95% {
    opacity: 1;
    background-color: ${COLORS.FIREPOWER}0;
  }
    
  100% {
    border: .5px ${COLORS.FIREPOWER} solid;
    border-radius: 50%;
    background-color: ${COLORS.FIREPOWER}0;
    transform: rotate(1800deg) scale(0);
    opacity: 0;
  }
`;

export const reactorOverloadShipAnimation = keyframes`
  0% { transform: translate3d(4px, 5px, 0) rotate(0deg); }
  10% { transform: translate3d(-8px, -6px, 0) rotate(-2deg); }
  20% { transform: translate3d(10px, 3px, 0) rotate(2deg); }
  30% { transform: translate3d(-3px, 6px, 0) rotate(0deg); }
  40% { transform: translate3d(8px, -5px, 0) rotate(2deg); }
  50% { transform: translate3d(-4px, 7px, 0) rotate(-2deg); }
  60% { transform: translate3d(10px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(2px, 4px, 0) rotate(-2deg); }
  80% { transform: translate3d(-9px, -5px, 0) rotate(2deg); }
  90% { transform: translate3d(4px, 5px, 0) rotate(0deg); }
  100% { transform: translate3d(-5px, -3px, 0) rotate(-2deg); }
`;

export const shieldBypassAnimation = keyframes`
  0% {
    opacity: 0;
    width: 100%;
    background-color: ${COLORS.ENERGY}20;
    border-width: 1px;
    filter: saturate(100%);
  }  

  10% {
    opacity: 1;
    width: 100%;
    background-color: ${COLORS.ENERGY}20;
    border-width: 1px;
    filter: saturate(100%);
  }

  90% {
    opacity: 1;
    width: 300vw;
    background-color: ${COLORS.ENERGY}80;
    border-width: 10px;
    filter: saturate(150%);
  }

  100% {
    opacity: 0;
    width: 300vw;
    height: 120%;
    background-color: ${COLORS.ENERGY}80;
    border-width: 10px;
    filter: saturate(150%);
  }
`;

export const systemsDecayAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scale(.5) rotate(0deg);
  }

  80% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    transform: scale(10) rotate(720deg);
  }
`;

export const skillsRechargeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
    border-width: 1px;
    border-radius: 40%;
  }

  10% {
    opacity: .75;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(1080deg);
    border-width: 200px;
    border-radius: 50%;
  }
`;

export const pressurePointAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg) scaleX(0);
    filter: brightness(100%) saturate(100%);
  }

  10% {
    opacity: .5;
    transform: rotate(-5deg) scaleX(10);
  }

  20% {
    transform: rotate(3deg) scaleX(10);
  }

  30% {
    transform: rotate(-4deg) scaleX(10);
  }

  40% {
    transform: rotate(2deg) scaleX(10);
  }

  50% {
    opacity: .5;
    transform: rotate(0deg) scaleX(10);
    filter: brightness(100%) saturate(100%);
    box-shadow: 0 0px 0px ${COLORS.PRECISION};
  }

  90% {
    opacity: 1;
    transform: rotate(0deg) scaleX(10);
    filter: brightness(150%) saturate(150%);
    box-shadow: 0 0px 5px ${COLORS.PRECISION};
  }

  100% {
    opacity: 0;
  }
`;

export const eagleEyeAnimation = keyframes`
  0% {
    transform: translateX(0) scale(1);
    opacity: 0;
    border-width: 2px;
  }

  10% {
    opacity: .5;
  }

  90% {
    opacity: .5;
  }

  100% {
    transform: translateX(200%) scale(4);
    opacity: 0;
    border-width: 5px;
  }
`;

export const blastEchoAnimation = keyframes`
  0% {
    transform: translateX(0) scale(.2);
    opacity: .5;
    box-shadow: -10px 0px 5px ${COLORS.PRECISION};
  }

  49% {
    box-shadow: -10px 0px 5px ${COLORS.PRECISION};
  }

  50% {
    opacity: .75;
    transform: translateX(500%) scale(2);
  }

  51% {
    box-shadow: -10px 0px 5px white;
  }

  100% {
    transform: translateX(0) scale(.2);
    opacity: .5;
    box-shadow: -10px 0px 5px white;
  }
`;

export const phantomStrikeTrailAnimation = keyframes`
  0% {
    transform: scaleX(.2) scaleY(1);
    opacity: 0;
  }

  30% {
    transform: scaleX(.2) scaleY(1);
    opacity: 0;
  }

  50% {
    transform: scaleX(6) scaleY(0.5);
    opacity: 1;
  }

  100% {
    transform: scaleX(0) scaleY(1);
  }
`;

export const phantomStrikeShipAnimation = keyframes`
  0% {
    transform: translateX(0) scaleY(1);
  }

  30% {
    transform: translateX(-20%) scaleY(1);
  }

  50% {
    transform: translateX(500%) scaleY(.8);
    opacity: 1;
  }

  51% {
    transform: translateX(500%) scaleY(1);
    opacity: 0;
  }

  79% {
    opacity: 0;
  }

  80% {
    transform: translateX(-200%) scaleY(1);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) scaleY(1);
  }
`;

export const mobPhantomStrikeShipAnimation = keyframes`
  0% {
    transform: translateX(0) scaleY(1);
  }

  30% {
    transform: translateX(20%) scaleY(1);
  }

  50% {
    transform: translateX(-500%) scaleY(.8);
    opacity: 1;
  }

  51% {
    transform: translateX(-500%) scaleY(1);
    opacity: 0;
  }

  79% {
    opacity: 0;
  }

  80% {
    transform: translateX(200%) scaleY(1);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) scaleY(1);
  }
`;

export const hyperShiftEffectAnimation = keyframes`
  0% {
    transform: scaleY(.2);
    opacity: 0;
  }

  10% {
    transform: scaleY(2);
    opacity: 1;
  }

  50% {
    transform: scaleY(2.5) ;
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    opacity: 0;
  }
`;

export const hyperShiftShipAnimation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  10% {
    transform: translateX(-20%);
    opacity: 0
  }

  15% {
    transform: translateX(20%);
    opacity: 1
  }

  20% {
    transform: translateX(-30%);
    opacity: .2
  }

  25% {
    transform: translateX(40%);
    opacity: 1;
  }

  30% {
    transform: translateX(-10%);
    opacity: 0;
  }

  35% {
    transform: translateX(-50%);
    opacity: 0.5;
  }

  40% {
    transform: translateX(50%);
    opacity: 0;
  }

  95% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const emergencyRepairsAnimation = keyframes`
  0% {
    opacity: .2;
    transform: scaleY(6) scaleX(0);
  }

  30% {
    opacity: .6;
    transform: scaleY(6) scaleX(1.5);
  }

  90% {
    opacity: 1;
    transform: scaleY(6) scaleX(2);
  }

  100% {
    opacity: 0;
    transform: scaleY(6) scaleX(0);
  }
`;

export const shieldRestoreAnimation = keyframes`
  0% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }

  10% {
    transform: rotate(180deg) scale(1.2);
    box-shadow: inset 0 0 5px 10px ${COLORS.RESILIENCE}20, 0 0 5px 10px ${COLORS.RESILIENCE}10;
  }

  20% {
    transform: rotate(360deg) scale(.8);
  }

  80% {
    transform: rotate(1080deg) scale(.8);
  }

  90% {
    transform: rotate(1440deg) scale(1.2);
    box-shadow: inset 0 0 30px 60px ${COLORS.RESILIENCE}80, 0 0 30px 60px ${COLORS.RESILIENCE}60;
  }

  100% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }
`;

export const mobShieldRestoreAnimation = keyframes`
  0% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }

  10% {
    transform: rotate(-180deg) scale(1.2);
    box-shadow: inset 0 0 5px 10px ${COLORS.RESILIENCE}20, 0 0 5px 10px ${COLORS.RESILIENCE}10;
  }

  20% {
    transform: rotate(-360deg) scale(.8);
  }

  80% {
    transform: rotate(-1080deg) scale(.8);
  }

  90% {
    transform: rotate(-1440deg) scale(1.2);
    box-shadow: inset 0 0 30px 60px ${COLORS.RESILIENCE}80, 0 0 30px 60px ${COLORS.RESILIENCE}60;
  }

  100% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1);
  }
`;

export const shieldStormAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }

  40% {
    transform: rotate(180deg) scale(.8);
    filter: saturate(150%) brightness(150%);
  }

  90% {
    transform: rotate(720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 1;
  }

  91% {
    transform: rotate(720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 0;
  }

  92% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 0; 
  }

  100% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 1;
  }
`;

export const mobShieldStormAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }

  40% {
    transform: rotate(-180deg) scale(.8);
    filter: saturate(150%) brightness(150%);
  }

  90% {
    transform: rotate(-720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 1;
  }

  91% {
    transform: rotate(-720deg) scale(10);
    filter: saturate(150%) brightness(150%);
    opacity: 0;
  }

  92% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 0; 
  }

  100% {
    transform: rotate(0deg) scale(1);
    filter: saturate(100%) brightness(100%);
    opacity: 1;
  }
`;

// Skill Mixins

const shieldBreakerMixin = css`
  border-top: 2px ${COLORS.FIREPOWER} solid;
  border-bottom: 2px ${COLORS.FIREPOWER} solid;
  animation: ${shieldBreakerAnimation} 1000ms ease-in forwards;
  transform-origin: center center;
  filter: saturate(150%) brightness(150%);
`;

const scattershotMixin = css`
  border: 1px ${COLORS.FIREPOWER} dotted;
  border-radius: 50%;
  animation: ${scattershotAnimation} 1000ms ease-in forwards;
`;

const powerSurgeMixin = css`
  filter: saturate(150%) brightness(150%);
  animation: ${powerSurgeAnimation} 1000ms linear forwards;
`;

const rapidFireMixin = css`
  border-right: 10px ${COLORS.SPEED} solid;
  border-radius: 50%;
  box-shadow: 0 0px 10px ${COLORS.SPEED};
  animation: ${rapidFireAnimation} 1000ms ease-in forwards;
  filter: brightness(150%) saturate(150%);
`;

const phantomStrikeMixin = css`
  height: 5px;
  background-color: ${COLORS.SPEED};
  box-shadow: 0 0px 10px ${COLORS.SPEED};
  animation: ${phantomStrikeTrailAnimation} 1000ms ease-in forwards;
  filter: brightness(150%) saturate(150%);
  transform-origin: center right;
  right: 75%;
`;

const phantomStrikeShipMixin = css`
  animation: ${phantomStrikeShipAnimation} 1000ms ease-in forwards;
`;

const mobPhantomStrikeShipMixin = css`
  animation: ${mobPhantomStrikeShipAnimation} 1000ms ease-in forwards;
`;

const hyperShiftMixin = css`
  width: 10px;
  background-color: ${COLORS.SPEED};
  box-shadow: 0 0px 10px ${COLORS.SPEED};
  border-radius: 50%;
  animation: ${hyperShiftEffectAnimation} 1000ms ease-in forwards;
  filter: brightness(150%) saturate(150%);
`;

const hyperShiftShipMixin = css`
  animation: ${hyperShiftShipAnimation} 1000ms ease-in forwards;
`;

const emergencyRepairsMixin = css`
  border-left: 2px #2fe322 solid;
  border-right: 2px #2fe322 solid;
  background-color: #2fe32220;
  box-shadow: 0 0px 20px #2fe322;
  filter: saturate(120%) brightness(120%);
  animation: ${emergencyRepairsAnimation} 1000ms ease-out forwards;
`;

const shieldRestoreMixin = css`
  animation: ${shieldRestoreAnimation} 1000ms linear forwards;
`;

const mobShieldRestoreMixin = css`
  animation: ${mobShieldRestoreAnimation} 1000ms linear forwards;
`;

const shieldStormMixin = css`
  animation: ${shieldStormAnimation} 1000ms linear forwards;
`;

const mobShieldStormMixin = css`
  animation: ${mobShieldStormAnimation} 1000ms linear forwards;
`;

const energyBlastMixin = css`
  background-color: ${COLORS.ENERGY}50;
  border-right: 5px ${COLORS.ENERGY} solid;
  border-radius: 50%;
  animation: ${energyBlastAnimation} 1000ms ease-in forwards;
`;

const reactorOverloadMixin = css`
  border-radius: 50%;
  animation: ${reactorOverloadAnimation} 1000ms ease-in forwards;
`;

const reactorOverloadShipMixin = css`
  border-radius: 50%;
  animation: ${reactorOverloadShipAnimation} 1000ms ease-in forwards;
`;

const shieldBypassMixin = css`
  border-radius: 500px;
  border: 1px ${COLORS.ENERGY}80 solid;
  animation: ${shieldBypassAnimation} 1000ms ease-in forwards;
`;

const systemsDecayMixin = css`
  background: radial-gradient(#000000 25%, ${COLORS.ENERGY} 100%);
  border-radius: 40%;
  animation: ${systemsDecayAnimation} 1000ms ease-in forwards;
`;

const skillsRechargeMixin = css`
  background-color: ${COLORS.ENERGY}25;
  border: 1px ${COLORS.ENERGY}80 solid;
  animation: ${skillsRechargeAnimation} 1000ms ease-in forwards;
`;

const pressurePointMixin = css`
  background-color: ${COLORS.PRECISION};
  animation: ${pressurePointAnimation} 1000ms linear forwards;
  transform-origin: center left;
  left: 50%;
  height: 2px;
`;

const eagleEyeMixin = css`
  transform-origin: center center;
  border-right: 2px ${COLORS.PRECISION} double;
  border-radius: 50%;
  animation: ${eagleEyeAnimation} 1000ms ease-out forwards;
  filter: saturate(150%) brightness(150%);
`;

const blastEchoMixin = css`
  transform-origin: center center;
  border-radius: 50%;
  animation: ${blastEchoAnimation} 1000ms ease-out forwards;
  box-shadow: -10px 0px 5px ${COLORS.PRECISION};
`;

export const SKILL_ANIMATIONS: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  shieldBreaker: shieldBreakerMixin,
  scattershot: scattershotMixin,
  powerSurge: powerSurgeMixin,
  rapidFire: rapidFireMixin,
  phantomStrike: phantomStrikeMixin,
  hyperShift: hyperShiftMixin,
  emergencyRepairs: emergencyRepairsMixin,
  energyBlast: energyBlastMixin,
  reactorOverload: reactorOverloadMixin,
  shieldBypass: shieldBypassMixin,
  systemsDecay: systemsDecayMixin,
  skillsRecharge: skillsRechargeMixin,
  pressurePoint: pressurePointMixin,
  eagleEye: eagleEyeMixin,
  blastEcho: blastEchoMixin,
};

export const SKILL_SHIP_ANIMATIONS: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  phantomStrike: phantomStrikeShipMixin,
  hyperShift: hyperShiftShipMixin,
  reactorOverload: reactorOverloadShipMixin,
};

export const SKILL_MOB_SHIP_ANIMATIONS: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  phantomStrike: mobPhantomStrikeShipMixin,
  hyperShift: hyperShiftShipMixin,
  reactorOverload: reactorOverloadShipMixin,
};

export const SKILL_SHIELDS_ANIMATIONS: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  shieldRestore: shieldRestoreMixin,
  shieldStorm: shieldStormMixin,
};

export const SKILL_MOB_SHIELDS_ANIMATIONS: {
  [key: string]: FlattenSimpleInterpolation;
} = {
  shieldRestore: mobShieldRestoreMixin,
  shieldStorm: mobShieldStormMixin,
};
