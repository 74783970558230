import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BattleRatingSmall from "components/base/BattleRatingSmall";
import Dialogs from "components/base/Dialogs";
import FightStats from "components/base/FightStats";
import ItemIcon from "components/base/ItemIcon";
import {
  FightOverlayActions,
  ItemImageWrapper,
  ItemInfo,
  ItemName,
  ItemWrapper,
  ListItem,
  OverlayBox,
  OverlayButton,
  OverlayList,
  OverlaySubtitle,
  OverlayTitle,
} from "components/base/Overlay";
import Skill from "components/base/Skill";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import useDialog from "components/hooks/useDialog";
import { retreatFight, startFight } from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { BattleRatings } from "types";
import {
  getBattleRatingData,
  getItemData,
  getMobOverlayDialog,
} from "utils/stats";

const DialogWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`;

const FightStatsWrapper = styled.div`
  margin-bottom: 25px;
`;

const SkillsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const Skills = styled.div`
  display: flex;
  gap: 5px;
`;

const RatingWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const EarnedRating = styled.div`
  cursor: pointer;
  font-size: 18px;
`;

export default function FightPreview() {
  const { data: characterData } = useSelector(getCharacter);
  const { battleRatings, skills: characterSkills } = characterData;
  const { opponent, status } = useSelector(getFight);
  const dispatch = useDispatch();

  const activeDialog = getMobOverlayDialog(opponent.dialogs, status);

  const { currentStep, currentDialog, hasAction } = useDialog(
    activeDialog || [],
    true
  );

  const previousRating = battleRatings[opponent.slug];

  return (
    <OverlayBox>
      <OverlayTitle>Starship Analysis: {opponent.name}</OverlayTitle>
      {!!opponent.isTutorial && (
        <DialogWrapper>
          <Dialogs
            avatar={opponent.images.pilot}
            currentStep={currentStep}
            currentDialog={currentDialog}
            hasAction={hasAction}
          />
        </DialogWrapper>
      )}
      <FightStatsWrapper>
        <OverlaySubtitle>Fighter Profiles</OverlaySubtitle>
        <FightStats />
        <SkillsWrapper>
          <Skills>
            {characterSkills
              .filter((skill) => !!skill.isTrained)
              .map((skill) => {
                return (
                  <Skill
                    key={skill.slug}
                    skill={skill}
                    characterData={characterData}
                    isSmall
                    isShowValues
                  />
                );
              })}
          </Skills>
          <Skills>
            {opponent.skills.map((skill) => {
              return (
                <Skill
                  key={skill.slug}
                  skill={skill}
                  characterData={opponent}
                  isSmall
                  isShowValues
                />
              );
            })}
          </Skills>
        </SkillsWrapper>
      </FightStatsWrapper>
      <OverlayList>
        <OverlaySubtitle>Battle Rewards</OverlaySubtitle>
        {Object.entries(opponent.drops).map(([key, drop]) => {
          const ratingKey = key as BattleRatings;
          const isEarned = previousRating === ratingKey;
          return (
            <ListItem key={drop.slug}>
              <ItemWrapper>
                <ItemImageWrapper>
                  <ItemIcon slug={drop.slug} />
                </ItemImageWrapper>
                <ItemName>{getItemData(drop.slug).name}</ItemName>
              </ItemWrapper>
              <ItemInfo>
                <RatingWrapper>
                  {!!previousRating && !!isEarned && (
                    <Trigger>
                      <EarnedRating>✦</EarnedRating>
                      <Tooltip>
                        <TooltipName>Highest Previous Rating</TooltipName>
                        <TooltipDesc>
                          {`${
                            getBattleRatingData(previousRating).name
                          } is the highest battle rating
                        you've earned on ${opponent.name} in previous battles.`}
                        </TooltipDesc>
                      </Tooltip>
                    </Trigger>
                  )}
                  <BattleRatingSmall rating={ratingKey} />
                </RatingWrapper>
              </ItemInfo>
            </ListItem>
          );
        })}
      </OverlayList>
      <FightOverlayActions>
        <OverlayButton
          $style="normal"
          $size="large"
          onClick={() => dispatch(retreatFight())}
        >
          Retreat
        </OverlayButton>
        <OverlayButton
          $style="important"
          $size="large"
          onClick={() => dispatch(startFight())}
        >
          Battle
        </OverlayButton>
      </FightOverlayActions>
    </OverlayBox>
  );
}
