import styled, { css } from "styled-components";

interface SettingOptionProps {
  $isActive: boolean;
}

export const SettingSubsection = styled.div``;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingValue = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

export const SettingName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

export const SettingOptions = styled.div`
  display: flex;
`;

const activeSettingMixin = css`
  color: #43fffa;
  cursor: auto;
  border-color: #43fffa;
  opacity: 1;
`;

export const SettingOption = styled.div<SettingOptionProps>`
  padding: 5px 20px;
  transition: all 200ms ease-in;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px transparent solid;
  opacity: 0.5;
  background-color: #43fffa15;

  ${(props) => props.$isActive && activeSettingMixin}

  :hover {
    opacity: 1;
  }
`;

export const SettingButtons = styled.div`
  display: flex;
  gap: 10px;
`;
