import React from "react";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import {
  getInventoryItemAmount,
  getItemData,
  getPropertyData,
} from "utils/stats";
import ItemIcon from "components/base/ItemIcon";
import { COLORS } from "utils/constants";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import {
  Item,
  ItemImageWrapper,
  ItemInfo,
  ItemName,
  ItemWrapper,
  Description,
} from "./Item";
import Button from "./Button";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";
import { ItemData } from "types";
import { ITEM_MAX_AMOUNT } from "redux/sagas/item";

interface ShopItemProps {
  slug: string;
  isBuy: boolean;
  onClick: (slug: string, quantity: number) => void;
}

interface ShopItemButtonProps {
  isBuy: boolean;
  itemData: ItemData;
  actionQuantity: number;
  inventoryQuantity: number;
  creditsAvailable: number;
  onClick: (slug: string, quantity: number) => void;
}

interface IconProps {
  image: string;
}

export const ItemPurchase = styled.div`
  display: flex;
  gap: 10px;
`;

export const ItemButtons = styled.div`
  display: flex;
  gap: 5px;
`;

export const ItemCredits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
  font-size: 16px;
  text-align: right;
`;

export const ItemCreditsIcon = styled.div<IconProps>`
  height: 14px;
  width: 14px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

export const TooltipCredits = styled.span`
  color: ${COLORS.CREDITS};
`;

const ShopItemButton = ({
  itemData,
  isBuy,
  actionQuantity,
  inventoryQuantity,
  creditsAvailable,
  onClick,
}: ShopItemButtonProps) => {
  const propertyInfo = getPropertyData("credits");

  const itemCredits = !!isBuy ? itemData.credits : itemData.sellCredits;
  const totalCredits = propertyInfo.rounder(itemCredits * actionQuantity);
  const actionText = !!isBuy ? "Buy" : "Sell";

  // Determine if player can take buy or sell action
  const notEnoughCreditsToBuy = !!isBuy && totalCredits > creditsAvailable;
  const tooMuchInventoryToBuy =
    !!isBuy && inventoryQuantity + actionQuantity > ITEM_MAX_AMOUNT;
  const notEnoughInventoryToSell = !isBuy && actionQuantity > inventoryQuantity;
  const isDisabled =
    notEnoughCreditsToBuy || tooMuchInventoryToBuy || notEnoughInventoryToSell;

  return (
    <Trigger>
      <Button
        $style="normal"
        $size="xsmall"
        $disabled={isDisabled}
        onClick={() => onClick(itemData.slug, actionQuantity)}
      >
        {actionQuantity}x
      </Button>
      <Tooltip isLeft>
        <TooltipName>
          {actionText} {actionQuantity}
        </TooltipName>
        <TooltipDesc>
          {actionText} {actionQuantity} {itemData.name} for{" "}
          <TooltipCredits>{totalCredits} credits</TooltipCredits>.
        </TooltipDesc>
      </Tooltip>
    </Trigger>
  );
};

export default function ShopItem({ slug, isBuy, onClick }: ShopItemProps) {
  const {
    data: { inventory, credits },
  } = useSelector(getCharacter);

  const itemData = getItemData(slug);
  const inventoryQuantity = getInventoryItemAmount(slug, inventory);

  return (
    <Item key={itemData.name}>
      <ItemWrapper>
        <ItemImageWrapper>
          <ItemIcon slug={slug} />
        </ItemImageWrapper>
        <ItemInfo>
          <ItemName>{itemData.name}</ItemName>
          {!!inventoryQuantity && (
            <Description>In Inventory: x{inventoryQuantity}</Description>
          )}
        </ItemInfo>
      </ItemWrapper>
      <ItemPurchase>
        <ItemCredits>
          <ItemCreditsIcon image={creditsImage} />
          {!!isBuy ? itemData.credits : itemData.sellCredits}
        </ItemCredits>
        <ItemButtons>
          <ShopItemButton
            itemData={itemData}
            isBuy={isBuy}
            actionQuantity={1}
            inventoryQuantity={inventoryQuantity}
            creditsAvailable={credits}
            onClick={onClick}
          />
          <ShopItemButton
            itemData={itemData}
            isBuy={isBuy}
            actionQuantity={10}
            inventoryQuantity={inventoryQuantity}
            creditsAvailable={credits}
            onClick={onClick}
          />
        </ItemButtons>
      </ItemPurchase>
    </Item>
  );
}
