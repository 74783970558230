import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import playersImage from "assets/players.svg";
import {
  CategoryGroup,
  CategoryName,
  CategorySeparator,
} from "components/base/DataScreen";
import ItemIcon from "components/base/ItemIcon";
import Skill from "components/base/Skill";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { BASE_STATS_INFO } from "data/baseStats";
import { useCallback } from "react";
import { getOnlineUsers, showMessage, showPlayerDetails } from "redux/actions";
import { getCharacter, getOnlinePlayers } from "redux/selectors";
import { CharacterBaseStats, PartTypes, UpgradeModel } from "types";
import { useImmer } from "use-immer";
import { COLORS } from "utils/constants";
import {
  MAX_BASE_STAT_VALUE,
  getPartData,
  getPilotData,
  getPlanetData,
  getPlatinumBattleRatingCount,
  getShipData,
} from "utils/stats";
import { DB_READ_THROTTLE_THRESHOLD } from "utils/storage/firebase";
import AvatarShip from "./AvatarShip";
import BattleRating from "components/base/BattleRating";

interface PlayerWrapperProps {
  isCurrentPlayer: boolean;
  isDetailed: boolean;
}

interface RefreshPlayersProps {
  $isDisabled: boolean;
}

interface IconProps {
  image: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface BarIconProps {
  image: string;
  color: string;
}

interface ComponentProps {
  color: string;
}

interface OnlinePlayersState {
  isThrottled: boolean;
}

const PlayersWrapper = styled.div`
  font-size: 18px;
`;

const PlayersInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const OnlineCount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  color: ${COLORS.ONLINE_PLAYERS};
`;

const CountText = styled.div``;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const PlayersIcon = styled.div<IconProps>`
  width: 18px;
  height: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ONLINE_PLAYERS};
`;

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
`;

const RefreshPlayers = styled.div<RefreshPlayersProps>`
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  color: ${COLORS.ONLINE_PLAYERS};
  transition: all 100ms ease-in;

  :hover {
    transform: scale(1.2);
    filter: brightness(120%);
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

const PlayersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const currentMixin = css`
  background-color: #43fffa10;
`;

const detailedMixin = css`
  border: 1px #ffffff10 solid;
  background-color: #ffffff05;
`;

const PlayerWrapper = styled.div<PlayerWrapperProps>`
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px transparent solid;
  ${(props) => !!props.isDetailed && detailedMixin}
  ${(props) => !!props.isCurrentPlayer && currentMixin}

  :hover {
    background-color: #ffffff05;
    ${(props) => !!props.isCurrentPlayer && currentMixin}
  }
`;

const BasicView = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Name = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
`;

const Level = styled.div`
  color: ${COLORS.LEVELS};
`;

const Ratings = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ShipImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  cursor: pointer;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const PilotWrapper = styled.div`
  height: 50px;
  width: 50px;
  border: 3px white solid;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
`;

const PilotImage = styled.img``;

const Location = styled.div`
  color: ${COLORS.LOCATION};
  margin-left: auto;
  display: flex;
`;

const Planet = styled.div`
  display: flex;
  align-items: center;
`;

const DetailedView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

const SectionName = styled(CategoryName)`
  font-size: 16px;
`;

const ShipStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;

const StatWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 2%;
`;

const StatIcon = styled.div<BarIconProps>`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const StatBar = styled.div`
  height: 16px;
  transform: skew(-30deg);
  flex: 1 1;
  background-color: #ffffff15;
  position: relative;
  display: flex;
  align-items: center;
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  height: 100%;
  z-index: 2;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;

const UpgradesWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Component = styled.div<ComponentProps>`
  background-color: black;
  border: 1px white solid;
  border-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 4px;
`;

const SkillsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export default function OnlinePlayers() {
  const dispatch = useDispatch();
  const onlinePlayers = useSelector(getOnlinePlayers);
  const {
    userName: currentPlayerName,
    data: {
      ui: { playerDetails },
    },
  } = useSelector(getCharacter);

  const [state, setState] = useImmer<OnlinePlayersState>({
    isThrottled: false,
  });

  const handleClickPlayer = (player: string | null) => {
    // Toggle player detailed view
    if (playerDetails !== player) {
      dispatch(showPlayerDetails(player));
    } else {
      dispatch(showPlayerDetails(null));
    }
  };

  const handleClickRefresh = useCallback(() => {
    if (!state.isThrottled) {
      setState((draft) => {
        draft.isThrottled = true;
      });
      dispatch(getOnlineUsers());
      dispatch(showMessage(`Online players list refreshed`));

      setTimeout(() => {
        setState((draft) => {
          draft.isThrottled = false;
        });
      }, DB_READ_THROTTLE_THRESHOLD);
    }
  }, [state.isThrottled, dispatch, setState]);

  return (
    <PlayersWrapper>
      <PlayersInfo>
        <OnlineCount>
          <CountText>Online Players:</CountText>
          <CountWrapper>
            <PlayersIcon image={playersImage} />
            {onlinePlayers.length}
          </CountWrapper>
        </OnlineCount>
        <Trigger>
          <RefreshPlayers
            $isDisabled={state.isThrottled}
            onClick={handleClickRefresh}
          >
            ⟳
          </RefreshPlayers>
          <Tooltip isLeft>
            <TooltipName>Refresh</TooltipName>
            <TooltipDesc>Get latest list of online players</TooltipDesc>
          </Tooltip>
        </Trigger>
      </PlayersInfo>
      <PlayersList>
        {onlinePlayers.map((player) => {
          const { userName } = player;
          const {
            ship,
            pilot,
            level,
            location,
            skills,
            totalBaseStats,
            shipUpgrades,
            battleRatings,
          } = player.data;

          const { name: shipClass, description: shipDesc } = getShipData(ship);
          const {
            name: pilotProfession,
            images: pilotImages,
            description: pilotDesc,
          } = getPilotData(pilot);
          const { name: planet } = getPlanetData(location.planet);

          const isDetailed = playerDetails === userName;
          const isCurrentPlayer = currentPlayerName === userName;
          const platinumRatings = getPlatinumBattleRatingCount(battleRatings);

          return (
            <PlayerWrapper
              key={userName}
              isDetailed={isDetailed}
              isCurrentPlayer={isCurrentPlayer}
              onClick={() => handleClickPlayer(userName)}
            >
              <BasicView>
                <ImagesWrapper>
                  <Trigger>
                    <ShipImageWrapper>
                      <AvatarWrapper>
                        <AvatarShip shipData={player.data} isPreview />
                      </AvatarWrapper>
                    </ShipImageWrapper>
                    <Tooltip>
                      <TooltipName>Starship: {shipClass}</TooltipName>
                      <TooltipDesc>{shipDesc}</TooltipDesc>
                    </Tooltip>
                  </Trigger>
                  <Trigger>
                    <PilotWrapper>
                      <PilotImage src={pilotImages.avatar} />
                    </PilotWrapper>
                    <Tooltip>
                      <TooltipName>Pilot: {pilotProfession}</TooltipName>
                      <TooltipDesc>{pilotDesc}</TooltipDesc>
                    </Tooltip>
                  </Trigger>
                </ImagesWrapper>
                <Name>{userName}</Name>
                <Level>✧{level}</Level>
                <Ratings>
                  <BattleRating rating="platinum" iconOnly noTooltip />
                  {platinumRatings}
                </Ratings>
                <Location>
                  <Planet>❍ {planet}</Planet>
                </Location>
              </BasicView>
              {isDetailed && (
                <DetailedView>
                  <CategoryGroup>
                    <SectionName>
                      Core Attributes
                      <CategorySeparator />
                    </SectionName>
                    <ShipStats>
                      {Object.values(BASE_STATS_INFO).map((stat) => {
                        const statSlug = stat.slug as keyof CharacterBaseStats;
                        const statValue = totalBaseStats[statSlug];

                        return (
                          <Trigger key={statSlug}>
                            <StatWrapper>
                              <StatIcon image={stat.image} color={stat.color} />
                              <StatBar>
                                <BarValue
                                  percentage={
                                    (statValue / MAX_BASE_STAT_VALUE) * 100
                                  }
                                  color={stat.color}
                                ></BarValue>
                              </StatBar>
                            </StatWrapper>
                            <Tooltip isAbove>
                              <TooltipName>{stat.name}</TooltipName>
                              <TooltipDesc>{stat.description}</TooltipDesc>
                            </Tooltip>
                          </Trigger>
                        );
                      })}
                    </ShipStats>
                  </CategoryGroup>
                  <CategoryGroup>
                    <SectionName>
                      Ship Upgrades
                      <CategorySeparator />
                    </SectionName>
                    <UpgradesWrapper>
                      {Object.entries(shipUpgrades).map(([key, value]) => {
                        const partSlug = key as PartTypes;
                        const partData = getPartData(partSlug);
                        const upgradeInfo = value as UpgradeModel;

                        return (
                          <Component key={partSlug} color={partData.color}>
                            <ItemIcon slug={upgradeInfo.slug} />
                          </Component>
                        );
                      })}
                    </UpgradesWrapper>
                  </CategoryGroup>
                  <CategoryGroup>
                    <SectionName>
                      Unlocked Skills
                      <CategorySeparator />
                    </SectionName>
                    <SkillsWrapper>
                      {skills
                        .filter((skill) => !!skill.isTrained)
                        .map((skill) => {
                          return (
                            <Skill
                              key={skill.slug}
                              skill={skill}
                              characterData={player.data}
                              isShowValues
                            />
                          );
                        })}
                    </SkillsWrapper>
                  </CategoryGroup>
                </DetailedView>
              )}
            </PlayerWrapper>
          );
        })}
      </PlayersList>
    </PlayersWrapper>
  );
}
