import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import academyTrainer from "assets/academy-trainer.svg";
import creditsImage from "assets/credits.svg";
import { DataScreen } from "components/base/DataScreen";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { exitBuilding, switchBuildingScreen } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { COLORS } from "utils/constants";
import PilotSkills from "./PilotSkills";

interface MenuItemProps {
  isActive: boolean;
}

interface IconProps {
  image: string;
}

const ShipworksInteriorWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.div`
  background-color: #0e4042;
  color: white;
  z-index: 1;
  display: flex;
  opacity: 0.9;
`;

const BuildingName = styled.div`
  padding: 9px 30px;
  font-size: 28px;
  color: #43fffa;
  text-transform: uppercase;
  font-weight: 600;
  background: #000000;
`;

const activeMixin = css`
  background-color: #00000020;
  color: #43fffa;
`;

const MenuItem = styled.div<MenuItemProps>`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 15px 30px;
  box-sizing: border-box;
  ${(props) => props.isActive && activeMixin};

  &:hover {
    background-color: #00000020;
  }
`;

const CreditsWrapper = styled.div`
  padding: 15px 30px;
  margin-left: auto;
`;

const Credits = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.CREDITS};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const ExitButton = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  color: #ffffff;
  padding: 15px 30px;
  background-color: #ffffff20;

  &:hover {
    background-color: #00000020;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  overflow-y: scroll;
`;

const DataScreenWrapper = styled.div`
  flex-basis: 70%;
`;

const EngineerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  align-items: stretch;
  align-self: end;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  opacity: 0.9;
`;

const Dialog = styled.div`
  font-size: 18px;
  background-color: #000000;
  align-self: stretch;
  padding: 30px;
`;

const EngineerName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 15px 30px;
`;

const EngineerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ShipworksEngineer = styled.img`
  display: block;
  width: 90%;
`;

const SCREENS = [
  {
    slug: "train",
    name: "Skills Training",
    component: <PilotSkills canTrain />,
    dialog:
      "What's up, champ? Let's get you trained up on some sweet new pilot skills.",
  },
];

export default function PilotAcademyInterior() {
  const dispatch = useDispatch();
  const {
    data: { credits, location },
  } = useSelector(getCharacter);

  const setScreen = SCREENS.find(
    (screen) => screen.slug === location.buildingScreen
  );
  const currentScreen = setScreen ? setScreen : SCREENS[0];

  // Initial dialog

  return (
    <ShipworksInteriorWrapper>
      <MenuWrapper>
        <BuildingName>Bishop Pilot Guild</BuildingName>

        {SCREENS.map((screen) => (
          <MenuItem
            key={screen.slug}
            isActive={currentScreen.slug === screen.slug}
            onClick={() => dispatch(switchBuildingScreen(screen.slug))}
          >
            {screen.name}
          </MenuItem>
        ))}

        <CreditsWrapper>
          <Trigger>
            <Credits>
              <CreditsIcon image={creditsImage} />
              {credits}
            </Credits>
            <Tooltip isLeft>
              <TooltipName>Credits Available</TooltipName>
              <TooltipDesc>
                How many credits you have to spend on repairs, stat resets,
                upgrades, and supplies.
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </CreditsWrapper>
        <ExitButton onClick={() => dispatch(exitBuilding())}>Exit</ExitButton>
      </MenuWrapper>
      <ContentWrapper>
        <DataScreenWrapper>
          <DataScreen>{currentScreen.component}</DataScreen>
        </DataScreenWrapper>
        <EngineerWrapper>
          <EngineerImageWrapper>
            <ShipworksEngineer src={academyTrainer} />
          </EngineerImageWrapper>
          <DialogWrapper>
            <EngineerName>Pilot Trainer Conway</EngineerName>
            <Dialog>
              {!!location.buildingDialog ? (
                location.buildingDialog
              ) : (
                <>{currentScreen.dialog}</>
              )}
            </Dialog>
          </DialogWrapper>
        </EngineerWrapper>
      </ContentWrapper>
    </ShipworksInteriorWrapper>
  );
}
