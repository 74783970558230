import { PilotModel, PilotTypes } from "types";

import weaponsSpecialistAvatarImage from "assets/pilot-weapons-specialist.svg";
import weaponsSpecialistInteriorImage from "assets/interior-pilot-weapons-specialist.svg";
import stuntPilotAvatarImage from "assets/pilot-stunt-pilot.svg";
import stuntPilotInteriorImage from "assets/interior-pilot-stunt-pilot.svg";
import mechanicalEngineerAvatarImage from "assets/pilot-mechanical-engineer.svg";
import mechanicalEngineerInteriorImage from "assets/interior-pilot-mechanical-engineer.svg";
import physicistAvatarImage from "assets/pilot-physicist.svg";
import physicistInteriorImage from "assets/interior-pilot-physicist.svg";
import sharpshooterAvatarImage from "assets/pilot-sharpshooter.svg";
import sharpshooterInteriorImage from "assets/interior-pilot-sharpshooter.svg";

export const PILOTS: Record<PilotTypes, PilotModel> = {
  weaponsSpecialist: {
    name: "Weapons Specialist",
    description:
      "Weapons Specialists love working on their ship's weapons (maybe too much). Their skills are about potentially doing very high damage.",
    images: {
      avatar: weaponsSpecialistAvatarImage,
      interior: weaponsSpecialistInteriorImage,
    },
    skills: ["shieldBreaker", "scattershot", "powerSurge"],
  },
  stuntPilot: {
    name: "Stunt Pilot",
    description:
      "Stunt Pilots are skilled in agile, nimble flying. This allows them to attack more often and deftly dodge attacks.",
    images: {
      avatar: stuntPilotAvatarImage,
      interior: stuntPilotInteriorImage,
    },
    skills: ["rapidFire", "phantomStrike", "hyperShift"],
  },
  mechanicalEngineer: {
    name: "Mechanical Engineer",
    description:
      "Mechanical Engineers are skilled in starship repair and enhancing their shields. Defense wins championships.",
    images: {
      avatar: mechanicalEngineerAvatarImage,
      interior: mechanicalEngineerInteriorImage,
    },
    skills: ["emergencyRepairs", "shieldRestore", "shieldStorm"],
  },
  physicist: {
    name: "Physicist",
    description:
      "Physicists are nerds who mainly focus on antimatter energy. This gives their ship all sorts of interesting abilities.",
    images: {
      avatar: physicistAvatarImage,
      interior: physicistInteriorImage,
    },
    skills: [
      "energyBlast",
      "reactorOverload",
      "shieldBypass",
      "systemsDecay",
      "skillsRecharge",
    ],
  },
  sharpshooter: {
    name: "Sharpshooter",
    description:
      "Sharpshooters will hit you where it hurts the most. Some call it luck, they call it skill. Kind of obnoxious.",
    images: {
      avatar: sharpshooterAvatarImage,
      interior: sharpshooterInteriorImage,
    },
    skills: ["pressurePoint", "eagleEye", "blastEcho"],
  },
};
