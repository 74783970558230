import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import Damage from "components/base/Damage";
import {
  damagedAnimation,
  dodgeAnimation,
  escapeAnimation,
  floatAnimation,
  loseAnimation,
} from "components/styles/animations";
import { SKILL_SHIP_ANIMATIONS } from "components/styles/skills";
import AvatarShip from "components/ui/AvatarShip";
import { enterShip } from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { FIGHT_DIALOG_STATUSES } from "utils/constants";
import { getSkillAnimation, hasAnimation } from "utils/stats";

interface AvatarWrapperProps {
  isMoving: boolean;
  isFighting: boolean;
}

interface AvatarImageWrapperProps {
  animations: string[];
  lastMove: string;
}

const AvatarWrapper = styled.div<AvatarWrapperProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${floatAnimation} 3.5s ease-in-out infinite;
  ${(props) => !!props.isMoving && "animation-play-state: paused;"}
  ${(props) => !!props.isFighting && "pointer-events: none;"}

  &:hover {
    filter: brightness(120%);
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  bottom: 80%;
`;

const Name = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
`;

const damagedMixin = css`
  animation: ${damagedAnimation} 300ms ease-in-out forwards;
`;

const dodgeMixin = css`
  animation: ${dodgeAnimation} 300ms ease-in-out forwards;
`;

const escapeMixin = css`
  animation: ${escapeAnimation} 500ms ease-in forwards;
`;

const loseMixin = css`
  animation: ${loseAnimation} 3000ms ease-in forwards;
`;

const AvatarImageWrapper = styled.div<AvatarImageWrapperProps>`
  height: 100%;
  transform: scaleX(${(props) => (props.lastMove === "right" ? 1 : -1)});
  ${(props) => (hasAnimation(props.animations, "damaged") ? damagedMixin : "")};
  ${(props) => (hasAnimation(props.animations, "dodge") ? dodgeMixin : "")};
  ${(props) => (hasAnimation(props.animations, "escape") ? escapeMixin : "")};
  ${(props) => (hasAnimation(props.animations, "lose") ? loseMixin : "")};
  ${(props) => getSkillAnimation(SKILL_SHIP_ANIMATIONS, props.animations)}
  cursor: pointer;
  position: relative;
`;

export default function Avatar() {
  const dispatch = useDispatch();
  const character = useSelector(getCharacter);
  const {
    userName,
    data: {
      damage,
      ui: { animations, isMoving, lastMove },
    },
  } = character;
  const { status } = useSelector(getFight);
  const isFighting =
    status !== "notFighting" && !FIGHT_DIALOG_STATUSES.includes(status);

  return (
    <AvatarWrapper
      isMoving={isMoving}
      isFighting={isFighting}
      onClick={() => dispatch(enterShip())}
    >
      <InfoWrapper>
        <Damage damageList={damage} />
        <Name>{userName}</Name>
      </InfoWrapper>

      <AvatarImageWrapper lastMove={lastMove} animations={animations}>
        <AvatarShip shipData={character.data} />
      </AvatarImageWrapper>
    </AvatarWrapper>
  );
}
