import React from "react";
import styled from "styled-components";

import { employSupply, installUpgrade, showMessage } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import ItemsList from "./ItemsList";

interface InventoryProps {
  isUpgrades: boolean;
  canAction: boolean;
}

export const InventoryWrapper = styled.div``;

export default function Inventory({ isUpgrades, canAction }: InventoryProps) {
  const dispatch = useDispatch();
  const character = useSelector(getCharacter);
  const {
    data: { inventory },
  } = character;

  const inventoryType = isUpgrades ? "upgrades" : "supplies";
  const inventoryItems = inventory[inventoryType].map((item) => item.slug);

  const handleInstallUpgrade = (slug: string) => {
    if (!!canAction) {
      dispatch(installUpgrade(slug));
    } else {
      dispatch(
        showMessage(`Find the nearest Shipworks to install this upgrade`)
      );
    }
  };

  const handleEmploySupply = (slug: string) => {
    dispatch(employSupply({ slug, showMessage: true }));
  };

  const handleClickItem = isUpgrades
    ? handleInstallUpgrade
    : handleEmploySupply;

  return (
    <InventoryWrapper>
      <ItemsList
        items={inventoryItems}
        isUpgrades={isUpgrades}
        isInventory
        canAction={canAction}
        onClick={handleClickItem}
      />
    </InventoryWrapper>
  );
}
