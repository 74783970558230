import styled from "styled-components";
import { COLORS } from "utils/constants";
import creditsImage from "assets/credits.svg";

export const DataScreen = styled.div`
  background-color: #0a1616;
  color: white;
  z-index: 2;
  opacity: 0.95;
  height: 100%;
`;

export const DataWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  text-transform: uppercase;
  color: #43fffa;
  font-size: 24px;
  font-weight: 600;
  padding: 30px 30px 15px;
`;

export const SectionGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const SectionGroup = styled.div``;

export const SectionSubtitle = styled.div`
  color: #2da5b2;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const SectionContent = styled.div`
  flex-grow: 1;
  padding: 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export const SectionContentScroll = styled(SectionContent)`
  overflow-y: scroll;
`;

export const CategoryGroup = styled.div``;

export const CategoryName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin-bottom: 10px;
`;

export const CategorySeparator = styled.div`
  width: 100%;
  border-bottom: #2da5b2 0.5px solid;
  opacity: 0.75;
`;

export const ScreenButtonWrapper = styled.div`
  margin-top: 30px;
  align-self: end;
`;

export const ButtonCredits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

export const ButtonCreditsIcon = styled.div`
  height: 18px;
  width: 18px;
  mask: url(${creditsImage}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;
