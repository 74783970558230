import { MusicTrackModel, PlaylistModel } from "types";

import lightVoyagersThemeMusic from "assets/music/light-voyager-theme.mp3";
import hyperlancerMusic from "assets/music/hyperlancer.mp3";
import neonGalaxyMusic from "assets/music/neon-galaxy.mp3";
import sectorMusic from "assets/music/sector.mp3";
import lockheedMusic from "assets/music/lockheed.mp3";
import hollowlandsMusic from "assets/music/hollowlands.mp3";
import grimlockMusic from "assets/music/grimlock.mp3";
import straylightMusic from "assets/music/straylight.mp3";
import astraMusic from "assets/music/astra.mp3";
import electraMusic from "assets/music/electra.mp3";
import silverhallMusic from "assets/music/silverhall.mp3";
import rookWickWittMusic from "assets/music/rookwickwitt.mp3";

export interface PlaylistData {
  [key: string]: PlaylistModel;
}

export interface MusicData {
  [key: string]: MusicTrackModel;
}

export const PLAYLISTS: PlaylistData = {
  titleScreen: ["lightVoyagersTheme"],
  bishop: [
    "hyperlancer",
    "neonGalaxy",
    "sector",
    "lockheed",
    "hollowlands",
    "grimlock",
    "straylight",
    "rookWickWitt",
  ],
  seneca: ["astra", "electra", "silverhall"],
};

export const MUSIC: MusicData = {
  lightVoyagersTheme: {
    name: "Light Voyagers Theme",
    audioFile: lightVoyagersThemeMusic,
  },
  hyperlancer: {
    name: "Hyperlancer",
    audioFile: hyperlancerMusic,
  },
  neonGalaxy: {
    name: "Neon Galaxy",
    audioFile: neonGalaxyMusic,
  },
  sector: {
    name: "Sector",
    audioFile: sectorMusic,
  },
  lockheed: {
    name: "Lockheed",
    audioFile: lockheedMusic,
  },
  hollowlands: {
    name: "Hollowlands",
    audioFile: hollowlandsMusic,
  },
  grimlock: {
    name: "Grimlock",
    audioFile: grimlockMusic,
  },
  straylight: {
    name: "Straylight",
    audioFile: straylightMusic,
  },
  astra: {
    name: "Astra",
    audioFile: astraMusic,
  },
  electra: {
    name: "Electra",
    audioFile: electraMusic,
  },
  silverhall: {
    name: "Silverhall",
    audioFile: silverhallMusic,
  },
  rookWickWitt: {
    name: "RookWickWitt",
    audioFile: rookWickWittMusic,
  },
};
