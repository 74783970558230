import { AREAS, PLANETS } from "data/areas";
import { BASE_STATS, BASE_STATS_INFO, BaseStatInfo } from "data/baseStats";
import { BATTLE_RATINGS } from "data/battleRatings";
import { BUILDINGS } from "data/buildings";
import {
  DERIVED_STATS,
  DerivedStatInfo,
  STAT_RANGES,
  StatRangeInfo,
} from "data/derivedStats";
import { MOBS } from "data/mobs";
import { MUSIC, PLAYLISTS } from "data/music";
import { NPCS } from "data/npcs";
import { PARTS } from "data/parts";
import { PILOTS } from "data/pilots";
import { SHIPS, ShipsData } from "data/ships";
import { SHOPS } from "data/shops";
import { SUPPLIES, SUPPLY_TYPES } from "data/supplies";
import { GRADES, UPGRADES } from "data/upgrades";
import { FlattenSimpleInterpolation } from "styled-components";
import {
  AreaModel,
  BattleRatingModel,
  BattleRatings,
  BattleRatingsData,
  BuildingModel,
  CharacterBaseStats,
  CharacterDerivedStats,
  DamageModel,
  FightStatuses,
  GradeModel,
  GroupedItemsData,
  InstalledUpgradesData,
  InventoryItemModel,
  InventoryModel,
  ItemData,
  MobDialogsModel,
  MobDropModel,
  MobDrops,
  MobModel,
  ModifiedDerivedStats,
  MusicTrackModel,
  NPCModel,
  PartModel,
  PartTypes,
  PilotModel,
  PilotTypes,
  PlanetModel,
  PlaylistModel,
  Properties,
  PropertyModel,
  RequirementsModel,
  ShipModel,
  ShipTypes,
  ShopModel,
  SkillModel,
  SkillSlugs,
  StatRanges,
  SupplyModel,
  SupplyTypeModel,
  SupplyTypes,
  UpgradeModel,
} from "types";
import { roundOneDecimal, roundWhole } from "./formatters";
import { SKILLS } from "data/skills";
import { getDerivedStatSets } from "redux/selectors";
import { PROPERTIES } from "data/properties";

export const MAX_LEVEL = 50;
export const LEVEL_EXP_MULTIPLIER = 1.5;
export const BASE_LEVEL_EXP = 1000;
export const MOB_EXP_PER_LEVEL = 200;
export const MOB_CREDITS_PER_LEVEL = 20;
export const REPAIR_HEALTH_PER_CREDIT = 5;
export const SWITCH_SHIP_CREDITS_PER_LEVEL = 50;
export const CREDITS_PER_STAT_TOKEN = 20;
export const CREDITS_PER_SKILL_TOKEN = 20;
export const UPGRADE_BASE_CREDITS = 50;
export const MAX_BASE_STAT_VALUE = 25;
export const MOB_BASE_STATS_MODIFIERS_TOTAL = 10;
export const MOB_BASE_STATS_COSTS_TOTAL = 10;

export const CRITICAL_HIT_WEIGHTED_THRESHOLD = 0.95;
export const DODGE_WEIGHTED_THRESHOLD = 0.05;
export const ESCAPE_WEIGHTED_THRESHOLD = 0.5;
export const MOB_SKILL_THRESHOLD = 0.5;

export const calculateLevel = (totalExp: number): number => {
  let level = 0;
  let remainingExp = totalExp;
  while (remainingExp >= 0 && level < MAX_LEVEL) {
    level++;
    remainingExp -= calculateNextLevelExp(level);
  }

  return level;
};

export const calculateCurrentLevelExp = (totalExp: number): number => {
  let level = 0;
  let remainingExp = totalExp;
  while (remainingExp >= 0) {
    level++;

    const nextLevelExp = calculateNextLevelExp(level);
    if (nextLevelExp > remainingExp) {
      break;
    }
    remainingExp -= nextLevelExp;
  }

  return roundWhole(remainingExp);
};

export const calculateNextLevelExp = (level: number): number => {
  return roundWhole(BASE_LEVEL_EXP * level ** LEVEL_EXP_MULTIPLIER);
};

export const getFightTurns = (
  characterTurnPriority: number,
  opponentTurnPriority: number,
  characterAttackSpeed: number,
  opponentAttackSpeed: number
) => {
  const isCharacterCurrentTurn = characterTurnPriority >= opponentTurnPriority;
  let isCharacterNextTurn;

  if (isCharacterCurrentTurn) {
    // Character turn
    isCharacterNextTurn =
      characterTurnPriority >= opponentTurnPriority + opponentAttackSpeed;
  } else {
    // Opponent turn
    isCharacterNextTurn =
      opponentTurnPriority < characterTurnPriority + characterAttackSpeed;
  }

  return {
    isCharacterCurrentTurn,
    isCharacterNextTurn,
  };
};

export const getNextConsecutiveTurns = (
  attackerTurnPriority: number,
  defenderTurnPriority: number,
  defenderAttackSpeed: number
) => {
  let nextTurns = 0;
  while (attackerTurnPriority >= defenderTurnPriority + defenderAttackSpeed) {
    nextTurns++;
    attackerTurnPriority -= defenderAttackSpeed;
  }
  return nextTurns;
};

export const getAvgAttackDamage = (
  minAttackDamage: number,
  maxAttackDamage: number
) => {
  const attackDamageInfo = getDerivedStatData("maxAttackDamage");

  const avgAttackDamage = (minAttackDamage + maxAttackDamage) / 2;

  return attackDamageInfo.rounder(avgAttackDamage);
};

export const getAvgWeakenParts = (
  minWeakenParts: number,
  maxWeakenParts: number
) => {
  const weakenPartsInfo = getDerivedStatData("maxWeakenParts");
  const avgWeakenParts = (minWeakenParts + maxWeakenParts) / 2;

  return weakenPartsInfo.rounder(avgWeakenParts);
};

export const getEscapeChance = (
  characterMovementSpeed: number,
  opponentMovementSpeed: number
): number => {
  const escapeChance =
    characterMovementSpeed / (characterMovementSpeed + opponentMovementSpeed);

  return escapeChance;
};

export const getWeightedAttackDiceRoll = (
  attackerAccuracy: number,
  defenderEvasion: number
): number => {
  const diceRoll = rollDice();

  const ratio = defenderEvasion / attackerAccuracy;
  const weightedDiceRoll = Math.pow(diceRoll, ratio);

  return weightedDiceRoll;
};

export const getWeightedEscapeDiceRoll = (
  characterMovementSpeed: number,
  opponentMovementSpeed: number
): number => {
  const diceRoll = rollDice();

  const ratio = opponentMovementSpeed / characterMovementSpeed;
  const weightedDiceRoll = Math.pow(diceRoll, ratio);

  return weightedDiceRoll;
};

export const getRegularAttackValues = (
  attackerStats: CharacterDerivedStats,
  defenderStats: CharacterDerivedStats
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenPartsInfo,
  } = DERIVED_STATS;

  // Roll dice to determine efficacy of damage + weaken parts
  const weightedAttackDiceRoll = getWeightedAttackDiceRoll(
    attackerStats.attackAccuracy,
    defenderStats.attackEvasion
  );

  // Determine Critical Hit or Dodge
  const isCriticalHit =
    weightedAttackDiceRoll >= CRITICAL_HIT_WEIGHTED_THRESHOLD;
  const isDodge = weightedAttackDiceRoll <= DODGE_WEIGHTED_THRESHOLD;

  let attackDamage = 0;
  let attackWeakenParts = 0;
  if (!isDodge) {
    attackDamage = getNumberFromDiceRoll(
      attackerStats.minAttackDamage,
      attackerStats.maxAttackDamage,
      weightedAttackDiceRoll
    );
    attackWeakenParts = getNumberFromDiceRoll(
      attackerStats.minWeakenParts,
      attackerStats.maxWeakenParts,
      weightedAttackDiceRoll
    );

    if (isCriticalHit) {
      attackDamage += attackerStats.criticalDamageBonus;
      attackWeakenParts += attackerStats.criticalWeakenBonus;
    }
  }

  return {
    attackDamage: maxAttackDamageInfo.rounder(attackDamage),
    attackWeakenParts: maxWeakenPartsInfo.rounder(attackWeakenParts),
    isCriticalHit,
    isDodge,
  };
};

export const getMultiplierAttackValues = (
  damage: number,
  weakenParts: number,
  attackMultiplier: number
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenpartsInfo,
  } = DERIVED_STATS;

  const attackDamage = damage * (1 + attackMultiplier);
  const attackWeakenParts = weakenParts * (1 + attackMultiplier);

  return {
    attackDamage: maxAttackDamageInfo.rounder(attackDamage),
    attackWeakenParts: maxWeakenpartsInfo.rounder(attackWeakenParts),
  };
};

export const getTotalDamageValuesAfterDefense = (
  attackDamage: number,
  attackWeakenParts: number,
  damageReduction: number,
  weakenPartsReduction: number
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenPartsInfo,
  } = DERIVED_STATS;

  // Calculate end damages after shield reductions
  const damageAfterReduction = Math.max(0, attackDamage - damageReduction);
  const weakenPartsAfterReduction = Math.max(
    0,
    attackWeakenParts - weakenPartsReduction
  );

  // Get actual amount of damage that was reduced by shields
  const damageReduced = Math.min(attackDamage, damageReduction);
  const weakenPartsReduced = Math.min(attackWeakenParts, weakenPartsReduction);

  // If all damage is completely reduced by shields, it's nullified
  let isNullified = false;
  if (
    damageAfterReduction === 0 &&
    weakenPartsAfterReduction === 0 &&
    (damageReduced > 0 || weakenPartsReduced > 0)
  ) {
    isNullified = true;
  }

  return {
    damage: maxAttackDamageInfo.rounder(damageAfterReduction),
    weakenParts: maxWeakenPartsInfo.rounder(weakenPartsAfterReduction),
    damageReduced,
    weakenPartsReduced,
    isNullified,
  };
};

export const getOpponentBaseStatTarget = (
  baseStatsTargets: (keyof CharacterBaseStats)[],
  characterBaseStats: CharacterBaseStats
): keyof CharacterBaseStats => {
  // Go in order of opponent's targeting, unless the base stat is already 0
  for (const i in baseStatsTargets) {
    if (characterBaseStats[baseStatsTargets[i]] > 0) {
      return baseStatsTargets[i];
    }
  }
  // If all are already 0, just target the first
  return baseStatsTargets[0];
};

export const calculateBattleRating = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
): BattleRatings => {
  const damageRatio: number = calculateDamageRatio(damageDealt, damageTaken);

  let battleRating: BattleRatings = "bronze";

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;
    if (damageRatio > ratingData.damageRatio) {
      battleRating = ratingKey;
      break;
    }
  }

  return battleRating;
};

export const calculateDamageRatio = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
) => {
  const DEFAULT_RATIO = 0.5;

  const damageDealtSum = damageDealt.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const damageTakenSum = damageTaken.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const totalDamageSum = damageDealtSum + damageTakenSum;

  const damageRatio: number =
    totalDamageSum > 0
      ? damageDealtSum / (damageDealtSum + damageTakenSum)
      : DEFAULT_RATIO;

  return damageRatio;
};

export const getPlatinumBattleRatingCount = (
  battleRatings: BattleRatingsData
) => {
  const platinumCount = Object.values(battleRatings).reduce(
    (count: number, rating: BattleRatings) => {
      return rating === "platinum" ? count + 1 : count;
    },
    0
  );

  return platinumCount;
};

export const getExpGained = (mobLevel: number, multiplier: number): number => {
  const baseExp = mobLevel * MOB_EXP_PER_LEVEL;
  const expGained = roundWhole(baseExp * multiplier);

  return expGained;
};

export const getStatPointsGained = (
  newLevel: number,
  levelsGained: number
): number => {
  const oldLevel = newLevel - levelsGained;
  let statPointsGained = 0;

  for (let i = oldLevel + 1; i <= newLevel; i++) {
    const statsPerLevel = Math.ceil(i / 10);
    statPointsGained += statsPerLevel;
  }

  return statPointsGained;
};

export const getCreditsGained = (
  mobLevel: number,
  multiplier: number
): number => {
  const baseCredits = mobLevel * MOB_CREDITS_PER_LEVEL;
  const creditsGained = roundWhole(baseCredits * multiplier);

  return creditsGained;
};

export const getPartData = (slug: PartTypes): PartModel => {
  return PARTS[slug];
};

export const getSupplyTypeData = (slug: SupplyTypes): SupplyTypeModel => {
  return SUPPLY_TYPES[slug];
};

export const getPropertyData = (slug: Properties): PropertyModel => {
  return PROPERTIES[slug];
};

export const getMobData = (slug: string): MobModel => {
  return MOBS[slug];
};

export const isSupplyItem = (slug: string): boolean => {
  if (!!SUPPLIES[slug]) {
    return true;
  }
  return false;
};

export const getSupplyData = (slug: string): SupplyModel => {
  return SUPPLIES[slug];
};

export const getUpgradeData = (slug: string) => {
  const upgradeData = UPGRADES[slug];
  if (!upgradeData) {
    console.error(slug);
  }
  const gradeData = getGradeData(upgradeData.grade);

  // Calculate stats for upgrade
  const derivedStats = calculateUpgradeStats(upgradeData, gradeData);

  // Calculate credits for upgrade
  const credits = calculateUpgradeCredits(upgradeData, gradeData);

  // Calculate credits for upgrade
  const requirementsSum = calculateRequirementsSum(upgradeData);

  return {
    ...upgradeData,
    gradeData,
    derivedStats,
    credits,
    requirementsSum,
  };
};

export const getGroupedSupplies = (supplies: string[]): GroupedItemsData => {
  const groupedSupplies = Object.entries(SUPPLY_TYPES).reduce(
    (acc, [key, value]) => {
      const supplyType = key as SupplyTypes;
      const supplyTypeData = value as SupplyTypeModel;
      const { name } = supplyTypeData;
      return {
        ...acc,
        [supplyType]: {
          name,
          items: [],
        },
      };
    },
    {} as GroupedItemsData
  );

  // Group supplies by their type property
  supplies.forEach((supply) => {
    const { type } = getSupplyData(supply);
    groupedSupplies[type].items.push(supply);
  });

  // Sort supplies within each group
  Object.keys(groupedSupplies).forEach((key) => {
    const supplyType = key as SupplyTypes;
    groupedSupplies[supplyType].items.sort((a, b) => {
      const aData = getSupplyData(a);
      const bData = getSupplyData(b);
      // Sort by payload values if they exist, otherwise use credits
      if (!!aData.payload && !!bData.payload) {
        return aData.payload - bData.payload;
      } else {
        return aData.credits - bData.credits;
      }
    });
  });

  return groupedSupplies;
};

export const getGroupedUpgrades = (upgrades: string[]): GroupedItemsData => {
  const groupedUpgrades = Object.entries(PARTS).reduce((acc, [key, value]) => {
    const partType = key as PartTypes;
    const partData = value as PartModel;
    const { name } = partData;
    return {
      ...acc,
      [partType]: {
        name,
        items: [],
      },
    };
  }, {} as GroupedItemsData);

  // Group upgrades by their part property
  upgrades.forEach((upgrade) => {
    const { part } = getUpgradeData(upgrade);
    groupedUpgrades[part].items.push(upgrade);
  });

  // Sort upgrades within each group
  Object.keys(groupedUpgrades).forEach((key) => {
    const partType = key as PartTypes;
    groupedUpgrades[partType].items.sort((a, b) => {
      const aInfo = getUpgradeData(a);
      const bInfo = getUpgradeData(b);
      // Sort by credits - takes into account reqs and grade
      return (
        aInfo.requirementsSum - bInfo.requirementsSum ||
        aInfo.gradeData.multiplier - bInfo.gradeData.multiplier
      );
    });
  });

  return groupedUpgrades;
};

export const getInventoryItemAmount = (
  slug: string,
  inventory: InventoryModel
): number => {
  let itemAmount = 0;

  const isSupply = isSupplyItem(slug);
  if (isSupply) {
    const inventorySupply = inventory.supplies.find(
      (supply: InventoryItemModel) => supply.slug === slug
    );
    if (inventorySupply) {
      itemAmount = inventorySupply.quantity;
    }
  } else {
    const inventoryUpgrade = inventory.upgrades.find(
      (upgrade: InventoryItemModel) => upgrade.slug === slug
    );
    if (inventoryUpgrade) {
      itemAmount = inventoryUpgrade.quantity;
    }
  }

  return itemAmount;
};

export const calculateUpgradeStats = (
  upgrade: UpgradeModel,
  grade: GradeModel
): ModifiedDerivedStats => {
  const { requirements } = upgrade;
  const { multiplier } = grade;

  const derivedStats: { [key: string]: number } = {};

  const statMods = upgrade.derivedStatsModifiers;
  for (const key in statMods) {
    const statType = key as keyof CharacterDerivedStats;
    const statWeight = statMods[statType] as number;
    const statInfo = getDerivedStatData(statType);

    // Pick the requirement value based on corresponding base stat of derived stat
    const reqValue = requirements[statInfo.baseStat] || 0;

    const statValue = statInfo.rounder(
      statInfo.incrementValue * statWeight * reqValue ** multiplier
    );

    derivedStats[statType] = statValue;
  }

  return derivedStats;
};

export const calculateUpgradeCredits = (
  upgrade: UpgradeModel,
  grade: GradeModel
): number => {
  const { multiplier } = grade;

  const requirementsSum = calculateRequirementsSum(upgrade);

  const credits = roundWhole(
    UPGRADE_BASE_CREDITS * requirementsSum ** multiplier
  );

  return credits;
};

export const calculateRequirementsSum = (upgrade: UpgradeModel): number => {
  const { requirements } = upgrade;

  const requirementsSum = Object.values(requirements).reduce(
    (acc, value) => acc + value,
    0
  );

  return requirementsSum;
};

export const getGradeData = (slug: string) => {
  return GRADES[slug];
};

export const getItemData = (slug: string): ItemData => {
  const propertyInfo = getPropertyData("credits");
  const itemData = isSupplyItem(slug)
    ? getSupplyData(slug)
    : getUpgradeData(slug);

  return {
    slug: itemData.slug,
    name: itemData.name,
    description: itemData.description,
    icon: itemData.icon,
    credits: itemData.credits,
    sellCredits: propertyInfo.rounder(itemData.credits / 2),
  };
};

export const getUpgradesStats = (
  installedUpgrades: InstalledUpgradesData | null,
  currentBaseStats: CharacterBaseStats,
  totalBaseStats: CharacterBaseStats
) => {
  const stats: CharacterDerivedStats = {
    maxHealth: 0,
    maxAttackDamage: 0,
    minAttackDamage: 0,
    attackSpeed: 0,
    attackAccuracy: 0,
    maxWeakenParts: 0,
    minWeakenParts: 0,
    damageReduction: 0,
    weakenPartsReduction: 0,
    criticalDamageBonus: 0,
    criticalWeakenBonus: 0,
    attackEvasion: 0,
    movementSpeed: 0,
    fasterRecharge: 0,
    energyMultiplier: 0,
  };

  if (!!installedUpgrades) {
    for (const partKey in installedUpgrades) {
      const part = partKey as PartTypes;
      const upgradeSlug = installedUpgrades[part];
      if (!!upgradeSlug) {
        const statMods = getUpgradeData(upgradeSlug)
          .derivedStats as CharacterDerivedStats;
        for (const key in statMods) {
          const statType = key as keyof CharacterDerivedStats;
          const statInfo = getDerivedStatData(statType);

          // Use weakened parts to determine efficacy of upgrade stats
          const percentage =
            currentBaseStats[statInfo.baseStat] /
            totalBaseStats[statInfo.baseStat];
          stats[statType] += statInfo.rounder(statMods[statType] * percentage);
        }
      }
    }
  }

  return stats;
};

export const getInvalidUpgradeParts = (
  installedUpgrades: InstalledUpgradesData | null,
  totalBaseStats: CharacterBaseStats
): PartTypes[] => {
  const invalidParts = [] as PartTypes[];

  // Ensure that character upgrade reqs are met by base stats
  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];
    if (!!upgradeSlug) {
      if (!isUpgradeValid(upgradeSlug, totalBaseStats)) {
        invalidParts.push(part);
      }
    }
  }
  return invalidParts;
};

export const isUpgradeValid = (
  upgradeSlug: string,
  totalBaseStats: CharacterBaseStats
) => {
  const { requirements } = getUpgradeData(upgradeSlug);

  // Check character base stat against upgrade reqs
  for (const key in requirements) {
    const baseStat = key as keyof CharacterBaseStats;
    const reqValue = requirements[baseStat] || 0;

    if (totalBaseStats[baseStat] < reqValue) {
      return false;
    }
  }
  return true;
};

export const getBattleRatingData = (slug: BattleRatings): BattleRatingModel => {
  return BATTLE_RATINGS[slug];
};

export const getMobDrops = (
  drops: MobDrops,
  battleRating: BattleRatings
): MobDropModel[] => {
  // Get drops based on rating, should get all lower rating drops as well
  const gainedDrops: MobDropModel[] = [];

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;

    const { value: fightRatingValue } = getBattleRatingData(battleRating);

    if (fightRatingValue >= ratingData.value) {
      // Compare value to actually battle rating value to grab each drop?
      if (drops[ratingKey]) {
        const drop = drops[ratingKey] as MobDropModel;
        gainedDrops.push(drop);
      }
    }
  }

  return gainedDrops;
};

export const getCharacterSkills = (
  pilot: PilotTypes,
  trainedSkills: SkillSlugs[],
  fasterRecharge: number
) => {
  const pilotData = getPilotData(pilot);

  // adjust skills to account for faster recharge stat
  const pilotSkills = pilotData.skills.map((skill) => {
    const skillInfo = getSkillData(skill);
    // Check if skill is trained by player
    const isTrained = !!skillInfo.isDefault || trainedSkills.includes(skill);

    return {
      ...skillInfo,
      recharge: Math.max(skillInfo.recharge - fasterRecharge, 1), // Minimum recharge is 1 turn
      isTrained,
    };
  });

  return pilotSkills;
};

export const getOpponentSkills = (
  skills: SkillSlugs[],
  fasterRecharge: number
) => {
  // adjust skills to account for faster recharge stat
  const pilotSkills = skills.map((skill) => {
    const skillInfo = getSkillData(skill);
    return {
      ...skillInfo,
      recharge: Math.max(skillInfo.recharge - fasterRecharge, 1), // Minimum recharge is 1 turn
      isTrained: true,
    };
  });

  return pilotSkills;
};

export const getChargedSkills = (
  skills: SkillModel[],
  skillsRecharge: { [key: string]: number }
) => {
  const chargedSkills = skills.filter((skill) => {
    const rechargeTurns = skillsRecharge[skill.slug];
    const isRecharging =
      typeof rechargeTurns !== "undefined" && rechargeTurns > 0;

    return !isRecharging;
  });

  return chargedSkills;
};

export const getFightSupplies = (inventorySupplies: InventoryItemModel[]) => {
  const fightSupplies = Object.entries(SUPPLY_TYPES)
    .filter(([, value]) => !!value.inFight)
    .reduce((acc, [key]) => {
      const supplyType = key as SupplyTypes;
      return {
        ...acc,
        [supplyType]: [],
      };
    }, {} as Record<SupplyTypes, string[]>);

  // Group supplies by their type property
  inventorySupplies.forEach((supply) => {
    const { type } = getSupplyData(supply.slug);
    if (fightSupplies[type]) {
      fightSupplies[type].push(supply.slug);
    }
  });

  // Sort supplies within each group
  Object.keys(fightSupplies).forEach((key) => {
    const supplyType = key as SupplyTypes;
    fightSupplies[supplyType].sort((a, b) => {
      const aData = getSupplyData(a);
      const bData = getSupplyData(b);
      // Sort by payload values if they exist, otherwise use credits
      if (!!aData.payload && !!bData.payload) {
        return aData.payload - bData.payload;
      } else {
        return aData.credits - bData.credits;
      }
    });
  });

  return fightSupplies;
};

export const getRepairSupplies = (inventorySupplies: InventoryItemModel[]) => {
  const repairSupplies = inventorySupplies
    .map((supply) => {
      return {
        ...supply,
        ...getSupplyData(supply.slug),
      };
    })
    .filter((supply) => supply.type === "health")
    .sort((a, b) => {
      if (!!a.payload && !!b.payload) {
        return a.payload - b.payload;
      } else {
        return a.credits - b.credits;
      }
    });

  return repairSupplies;
};

export const getPilotData = (pilot: PilotTypes): PilotModel => {
  return PILOTS[pilot];
};

export const getAllPilotsData = (): Record<PilotTypes, PilotModel> => {
  return PILOTS;
};

export const getSkillData = (skill: string): SkillModel => {
  return SKILLS[skill];
};

export const getShipData = (ship: ShipTypes): ShipModel => {
  return SHIPS[ship];
};

export const getAllShipsData = (): ShipsData => {
  return SHIPS;
};

export const getNPCData = (npc: string): NPCModel => {
  return NPCS[npc];
};

export const getPlanetData = (planet: string): PlanetModel => {
  return PLANETS[planet];
};

export const getAreaData = (area: string): AreaModel => {
  return AREAS[area];
};

export const getBuildingData = (building: string): BuildingModel => {
  return BUILDINGS[building];
};

export const getMusicData = (track: string): MusicTrackModel => {
  return MUSIC[track];
};

export const getPlaylistData = (track: string): PlaylistModel => {
  return PLAYLISTS[track];
};

export const getShopData = (shop: string): ShopModel => {
  return SHOPS[shop];
};

export const getBaseStatData = (
  baseStat: keyof CharacterBaseStats
): BaseStatInfo => {
  return BASE_STATS_INFO[baseStat];
};

export const getDerivedStatData = (
  stat: keyof CharacterDerivedStats
): DerivedStatInfo => {
  return DERIVED_STATS[stat];
};

export const getStatRangeData = (
  statRange: keyof StatRanges
): StatRangeInfo => {
  return STAT_RANGES[statRange];
};

export const getNextMusicTrack = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): string => {
  const currentIndex = currentPlaylist.indexOf(currentTrack);

  // If switched to a different playlist, just play first song of new playlist
  // Repeat playlist when ended
  let nextIndex = 0;
  if (currentIndex >= 0 && currentIndex < currentPlaylist.length - 1) {
    nextIndex = currentIndex + 1;
  }

  return currentPlaylist[nextIndex];
};

export const isTrackInPlaylist = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): boolean => {
  const isInPlaylist = currentPlaylist.includes(currentTrack);

  return isInPlaylist;
};

export const shuffleList = (array: string[]) => {
  return array
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
};

export const getNumberFromDiceRoll = (
  min: number,
  max: number,
  diceRoll: number
): number => {
  return diceRoll * (max - min) + min;
};

export const rollDice = (): number => {
  const diceRoll = Math.random();

  return diceRoll;
};

export const getRandomBaseStat = (): keyof CharacterBaseStats => {
  const baseStats = Object.values(BASE_STATS);

  const randomIndex = Math.floor(Math.random() * baseStats.length);

  const baseStat = baseStats[randomIndex];

  return baseStat;
};

export const getMaxBaseStatValue = (totalBaseStats: CharacterBaseStats) => {
  const maxValue = Math.max(...Object.values(totalBaseStats));

  return maxValue;
};

export const getShipDamage = (health: number, maxHealth: number): number => {
  const shipDamage = maxHealth - health;

  return shipDamage;
};

export const getRepairCost = (health: number, maxHealth: number): number => {
  const damageToRepair = getShipDamage(health, maxHealth);
  return Math.ceil(damageToRepair / REPAIR_HEALTH_PER_CREDIT);
};

export const getTotalWeakenedIntegrity = (
  weakenedBaseStats: CharacterBaseStats
): number => {
  let totalWeakenedIntegrity = 0;
  for (const key in weakenedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const weakenedValue = weakenedBaseStats[statSlug];
    totalWeakenedIntegrity += weakenedValue;
  }

  return totalWeakenedIntegrity;
};

export const getPartsToRestore = (weakenedBaseStats: CharacterBaseStats) => {
  const partsToRestore = Object.entries(weakenedBaseStats)
    .filter(([key, value]) => {
      const weakenedAmount = value as number;
      return weakenedAmount > 0;
    })
    .sort(([, a], [, b]) => {
      // Highest to lowest part damage
      const aValue = a as number;
      const bValue = b as number;
      return bValue - aValue;
    });
  return partsToRestore;
};

export const getSwitchShipCost = (level: number): number => {
  const propertyInfo = getPropertyData("credits");
  const totalSwitchCost = propertyInfo.rounder(
    level * SWITCH_SHIP_CREDITS_PER_LEVEL
  );
  return totalSwitchCost;
};

export const getLastWeakenedPartColor = (damage: DamageModel[] = []) => {
  let weakenedPartColor = "white";
  const lastDamage = damage[damage.length - 1];
  if (lastDamage && lastDamage.baseStatWeakened) {
    const baseStatInfo = getBaseStatData(lastDamage.baseStatWeakened);
    weakenedPartColor = baseStatInfo.color;
  }

  return weakenedPartColor;
};

export const hasAnimation = (
  animations: string[] = [],
  animation: string
): boolean => {
  return animations.includes(animation);
};

export const getSkillAnimation = (
  skillAnimations: {
    [key: string]: FlattenSimpleInterpolation;
  },
  activeAnimations: string[] = []
) => {
  const skill = Object.keys(skillAnimations).find((key) => {
    return activeAnimations.includes(key);
  });
  if (skill) {
    return skillAnimations[skill];
  } else {
    return null;
  }
};

export const getMobShipDialog = (
  dialogs: MobDialogsModel,
  status: FightStatuses
) => {
  const mobDialogs: Record<string, string[] | null> = {
    openingDialog: [dialogs.opening],
    winDialog: [dialogs.win],
    loseDialog: [dialogs.lose],
    fighting: dialogs.tutorial ? dialogs.tutorial.fight : null,
  };

  return mobDialogs[status];
};

export const getMobOverlayDialog = (
  dialogs: MobDialogsModel,
  status: FightStatuses
) => {
  const mobDialogs: Record<string, string[] | null> = {
    preview: dialogs.tutorial ? [dialogs.tutorial.preview] : null,
    winResults: dialogs.tutorial ? [dialogs.tutorial.winResults] : null,
    loseResults: dialogs.tutorial ? [dialogs.tutorial.loseResults] : null,
  };

  return mobDialogs[status];
};

export const getCombatLogMessage = () => {};

export const getTotalMedallions = (level: number) => {
  let totalMedallions = 0;
  for (let i = 2; i <= level; i++) {
    const medallionsPerLevel = Math.ceil(i / 10);
    totalMedallions += medallionsPerLevel;
  }

  return totalMedallions;
};

export const getStatsTokensSpent = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  const propertyInfo = getPropertyData("medallions");

  let totalTokensSpent = 0;
  const statsCosts = getShipData(ship).baseStatsCosts;

  // Figure out number of tokens to return - with differing stat costs
  for (const key in enhancedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const enhancedStatValue = enhancedBaseStats[statSlug];

    const tokenCost = enhancedStatValue * statsCosts[statSlug];
    totalTokensSpent += tokenCost;
  }

  return propertyInfo.rounder(totalTokensSpent);
};

export const getStatsResetCost = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);

  const resetCost = Math.ceil(tokensSpent * CREDITS_PER_STAT_TOKEN);

  return resetCost;
};

export const getSkillsTokensSpent = (skills: SkillModel[]): number => {
  const propertyInfo = getPropertyData("medallions");

  let totalTokensSpent = 0;

  skills.forEach((skill) => {
    if (!!skill.isTrained) totalTokensSpent += skill.medallions;
  });

  return propertyInfo.rounder(totalTokensSpent);
};

export const getSkillsResetCost = (skills: SkillModel[]): number => {
  const tokensSpent = getSkillsTokensSpent(skills);

  return Math.ceil(tokensSpent * CREDITS_PER_SKILL_TOKEN);
};

// BASE AND DERIVED STATS

export const calculateTotalBaseStats = (
  shipBaseStats: CharacterBaseStats,
  enhancedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower: shipBaseStats.firepower + enhancedBaseStats.firepower,
    resilience: shipBaseStats.resilience + enhancedBaseStats.resilience,
    speed: shipBaseStats.speed + enhancedBaseStats.speed,
    precision: shipBaseStats.precision + enhancedBaseStats.precision,
    energy: shipBaseStats.energy + enhancedBaseStats.energy,
  };
};

export const calculateCurrentBaseStats = (
  totalBaseStats: CharacterBaseStats,
  weakenedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower: roundOneDecimal(
      totalBaseStats.firepower - weakenedBaseStats.firepower
    ),
    resilience: roundOneDecimal(
      totalBaseStats.resilience - weakenedBaseStats.resilience
    ),
    speed: roundOneDecimal(totalBaseStats.speed - weakenedBaseStats.speed),
    precision: roundOneDecimal(
      totalBaseStats.precision - weakenedBaseStats.precision
    ),
    energy: roundOneDecimal(totalBaseStats.energy - weakenedBaseStats.energy),
  };
};

export const calculateMobBaseStats = (
  level: number,
  baseStatsModifiers: CharacterBaseStats,
  baseStatsCosts: CharacterBaseStats
): CharacterBaseStats => {
  // Mob base stats depend on level and modifiers
  // Total modifiers should add up to 15

  // Should take into account cost - 1 medallion doesn't equal 1 stat
  // Give mobs an extra medallion (mistake but don't want to redo all mob upgrades)
  const totalMedallions = getTotalMedallions(level) + 1;

  // Put together initial mob base stats object
  const mobBaseStats = Object.values(BASE_STATS).reduce(
    (mobBaseStats, baseStat) => {
      const mobBaseStat = calculateMobBaseStat(
        baseStatsModifiers[baseStat],
        baseStatsCosts[baseStat],
        totalMedallions
      );
      return {
        ...mobBaseStats,
        [baseStat]: mobBaseStat,
      };
    },
    { firepower: 0, resilience: 0, speed: 0, precision: 0, energy: 0 }
  );

  return mobBaseStats;
};

const calculateMobBaseStat = (
  modifier: number,
  cost: number,
  totalMedallions: number
): number => {
  // Add boost of 5 mob base stats to counter the character's initial 10
  const initialStatValue = modifier / 2;

  return roundOneDecimal(
    Math.min(
      initialStatValue +
        (modifier / MOB_BASE_STATS_MODIFIERS_TOTAL) * (totalMedallions / cost),
      MAX_BASE_STAT_VALUE
    )
  );
};

export const calculateInitialMobStats = (mobSlug: string) => {
  const { level, baseStatsModifiers, baseStatsCosts, installedUpgrades } =
    getMobData(mobSlug);

  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  const derivedStats = getDerivedStatSets(
    totalBaseStats,
    totalBaseStats,
    installedUpgrades
  );

  return {
    totalBaseStats,
    derivedStats,
  };
};

// Calculate Derived Stats

export const getBaseDerivedStats = (
  baseStats: CharacterBaseStats
): CharacterDerivedStats => {
  const stats = Object.entries(DERIVED_STATS).reduce((acc, [key, value]) => {
    const statType = key as keyof CharacterDerivedStats;
    const statInfo = value as DerivedStatInfo;
    return {
      ...acc,
      [statType]: calculateBaseDerivedStat(
        statInfo,
        baseStats[statInfo.baseStat]
      ),
    };
  }, {} as any);

  // Make minimum attack damage always less than maximum
  if (stats.minAttackDamage > stats.maxAttackDamage) {
    stats.minAttackDamage = stats.maxAttackDamage;
  }
  // Make minimum weaken parts always less than maximum
  if (stats.minWeakenParts > stats.maxWeakenParts) {
    stats.minWeakenParts = stats.maxWeakenParts;
  }

  return stats;
};

export const calculateBaseDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseStatValue: number
): number => {
  return derivedStat.rounder(
    derivedStat.startingValue + baseStatValue * derivedStat.incrementValue
  );
};

// SKILLS

export const getRechargeTurns = (recharge: number) => {
  // If decimal, use that remainder to determine whether to round up or down
  const remainder = recharge % 1;

  const diceRoll = rollDice();
  const shouldRoundDown = diceRoll > remainder;

  const turns = shouldRoundDown ? Math.floor(recharge) : Math.ceil(recharge);

  return turns;
};

// Calculate individual skill values

export const getShieldBreakerDamage = (stats: CharacterDerivedStats) => {
  const SHIELD_BREAKER_MULTIPLIER = 2;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SHIELD_BREAKER_MULTIPLIER * stats.maxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getScattershotDamage = (stats: CharacterDerivedStats) => {
  const SCATTERSHOT_MULTIPLIER = 3;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SCATTERSHOT_MULTIPLIER * stats.maxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPowerSurgeDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamage = defenderDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamage = skillsValues.powerSurge || 0;

  const minAttackDamage = 0;
  const initialMaxDamage = Math.max(totalDamage - usedDamage, 0);
  const initialMaxWeakenParts = 0;

  // Get max attack damage with energy multiplier in mind, for the description values
  const { attackDamage: maxAttackDamage } = getMultiplierAttackValues(
    initialMaxDamage,
    initialMaxWeakenParts,
    stats.energyMultiplier
  );

  // Roll dice to determine efficacy of damage + weaken parts
  const diceRoll = rollDice();

  const actualAttackDamage = getNumberFromDiceRoll(
    minAttackDamage,
    initialMaxDamage,
    diceRoll
  );

  const initialDamage = actualAttackDamage;
  const initialWeakenParts = initialMaxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    maxAttackDamage,
    totalDamage,
  };
};

export const getRapidFireDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = stats.minAttackDamage;
  const initialWeakenParts = stats.minWeakenParts;
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getHyperShiftDamage = (
  stats: CharacterDerivedStats,
  nextConsecutiveTurns: number
) => {
  // Double for each next consecutive turn
  const avgDamage = getAvgAttackDamage(
    stats.minAttackDamage,
    stats.maxAttackDamage
  );
  const initialDamage = avgDamage * 2 ** nextConsecutiveTurns;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPhantomStrikeDamage = (stats: CharacterDerivedStats) => {
  // Avg damage
  const initialDamage = getAvgAttackDamage(
    stats.minAttackDamage,
    stats.maxAttackDamage
  );
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEmergencyRepairHealth = (stats: CharacterDerivedStats) => {
  const healthInfo = getDerivedStatData("maxHealth");

  const INITIAL_REPAIR_PERCENTAGE = 0.25;

  const healPercentage =
    INITIAL_REPAIR_PERCENTAGE * (1 + stats.energyMultiplier);

  const healAmount = stats.maxHealth * healPercentage;

  return {
    healAmount: healthInfo.rounder(healAmount),
    healPercentage,
  };
};

export const getShieldRestoreAmount = (
  stats: CharacterDerivedStats,
  totalBaseStats: CharacterBaseStats
) => {
  const weakenPartsInfo = getDerivedStatData("maxWeakenParts");

  const INITIAL_RESTORE_PERCENTAGE = 0.25;

  const restorePercentage =
    INITIAL_RESTORE_PERCENTAGE * (1 + stats.energyMultiplier);

  const restoreAmount = totalBaseStats.resilience * restorePercentage;

  return {
    restoreAmount: weakenPartsInfo.rounder(restoreAmount),
    restorePercentage,
  };
};

export const getShieldStormDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamageReduced = defenderDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damageReduced,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamageReduced = skillsValues.shieldStorm || 0;
  const damageReduced = Math.max(totalDamageReduced - usedDamageReduced, 0);

  const initialDamage = damageReduced;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    totalDamageReduced,
  };
};

export const getEnergyBlastDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 5;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getReactorOverloadDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 10;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getShieldBypassDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 2;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getSystemsDecayDamage = (stats: CharacterDerivedStats) => {
  const SYSTEMS_DECAY_MULTIPLIER = 1;

  const initialDamage = 0;
  const initialWeakenParts = SYSTEMS_DECAY_MULTIPLIER;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPressurePointDamage = (
  stats: CharacterDerivedStats,
  skillsValues: { [key: string]: number }
) => {
  const PRESSURE_POINT_MULTIPLIER_PER_USE = 2;
  const MAX_DOUBLING_TIMES = 4;

  const useCount = Math.min(
    skillsValues.pressurePoint || 0,
    MAX_DOUBLING_TIMES
  );

  // Start with at least 1 damage if min attack is 0
  const initialDamage = Math.max(stats.minAttackDamage, 1);
  const initialWeakenParts = 0;

  // Double the damage based on how many time it's been used
  const doubledDamage =
    initialDamage * PRESSURE_POINT_MULTIPLIER_PER_USE ** useCount;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    doubledDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEagleEyeDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = stats.maxAttackDamage + stats.criticalDamageBonus;
  const initialWeakenParts = stats.maxWeakenParts + stats.criticalWeakenBonus;

  // Get critical hit damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getBlastEchoDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[]
) => {
  const BLAST_ECHO_MULTIPLIER = 3;

  const avgWeakenParts = getAvgWeakenParts(
    stats.minWeakenParts,
    stats.maxWeakenParts
  );

  // Get triple the average weaken stats
  const initialDamage = 0;
  const initialWeakenParts = BLAST_ECHO_MULTIPLIER * avgWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  // Get last weakened base stat
  let lastWeakenedBaseStat = null;
  const lastDamage = [...defenderDamages].pop();
  if (lastDamage) {
    lastWeakenedBaseStat = lastDamage.baseStatWeakened;
  }

  return {
    attackDamage,
    attackWeakenParts,
    lastWeakenedBaseStat,
  };
};

// Data tests, ensure that assumptions about static data are true, these are run by Jest

export const areAllMobInstalledUpgradesValid = (mobData: MobModel) => {
  // Ensure that all mob upgrades reference real upgrades
  const { name, installedUpgrades } = mobData;

  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];

    if (!!upgradeSlug) {
      const upgradeInfo = getUpgradeData(upgradeSlug);
      if (!upgradeInfo) {
        console.log(
          `Mob ${name} has installed upgrade ${upgradeSlug}, which doesn't exist`
        );
        return false;
      }
    }
  }
  return true;
};

export const areAllMobDropsValid = (mobData: MobModel) => {
  // Ensure that all mob upgrades reference real upgrades
  const { name, drops } = mobData;

  for (const ratingKey in drops) {
    const rating = ratingKey as BattleRatings;
    const drop = drops[rating] as MobDropModel;

    if (isSupplyItem(drop.slug)) {
      const supplyInfo = getSupplyData(drop.slug);

      if (!supplyInfo) {
        console.log(`Mob ${name} has drop ${drop.slug}, which doesn't exist`);
        return false;
      }
    } else {
      const upgradeInfo = getUpgradeData(drop.slug);

      if (!upgradeInfo) {
        console.log(`Mob ${name} has drop ${drop.slug}, which doesn't exist`);
        return false;
      }
    }
  }

  return true;
};

export const doMobUpgradesMeetReqs = (mobData: MobModel) => {
  // Ensure that mob upgrade reqs are met by mob stats
  const { name, level, baseStatsModifiers, baseStatsCosts, installedUpgrades } =
    mobData;
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );
  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];
    if (!!upgradeSlug) {
      const { name: upgradeName } = getUpgradeData(upgradeSlug);

      // Check mob base stat against upgrade req
      if (!isUpgradeValid(upgradeSlug, totalBaseStats)) {
        console.log(
          `${name} has ${upgradeName} installed, but does not meet stat requirements`
        );
        return false;
      }
    }
  }
  return true;
};

export const areMobUpgradesCorrectParts = (mobData: MobModel) => {
  // Ensure that mob upgrades are installed in the right part
  const { name, installedUpgrades } = mobData;

  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];
    if (!!upgradeSlug) {
      const { name: partName } = getPartData(part);
      const { part: upgradePart, name: upgradeName } =
        getUpgradeData(upgradeSlug);
      const { name: upgradePartName } = getPartData(upgradePart);

      // Check mob base stat against upgrade req
      if (part !== upgradePart) {
        console.log(
          `${upgradeName} is a ${upgradePartName}, but ${name} has it installed as a ${partName}`
        );
        return false;
      }
    }
  }
  return true;
};

export const doMobDropsMeetReqs = (mobData: MobModel) => {
  // Ensure that mob drop reqs are met by mob stats
  const { name, level, baseStatsModifiers, baseStatsCosts, drops } = mobData;
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );
  for (const ratingKey in drops) {
    const rating = ratingKey as BattleRatings;
    const drop = drops[rating] as MobDropModel;

    if (isSupplyItem(drop.slug)) {
      // Only test upgrades
      continue;
    }

    const { name: upgradeName } = getUpgradeData(drop.slug);

    // Check mob base stat against upgrade req
    if (!isUpgradeValid(drop.slug, totalBaseStats)) {
      console.log(
        `${name} has ${upgradeName} drop, but does not meet stat requirements`
      );
      return false;
    }
  }
  return true;
};

export const areMobBaseStatsModifiersValid = (mobData: MobModel) => {
  // Ensure that mob base stat modifiers sum up to 10
  const { name, baseStatsModifiers } = mobData;

  const statModsSum = Object.values(baseStatsModifiers).reduce(
    (sum: number, statMod: number) => sum + statMod,
    0
  );

  if (statModsSum !== MOB_BASE_STATS_MODIFIERS_TOTAL) {
    console.log(
      `${name} has base stat modifiers sum of ${statModsSum}. The expected sum is ${MOB_BASE_STATS_MODIFIERS_TOTAL}`
    );
    return false;
  }
  return true;
};

export const areMobBaseStatCostsValid = (mobData: MobModel) => {
  // Ensure that mob base stat costs sum up to 10
  const { name, baseStatsCosts } = mobData;

  const statCostsSum = Object.values(baseStatsCosts).reduce(
    (sum: number, statCost: number) => sum + statCost,
    0
  );

  if (statCostsSum !== MOB_BASE_STATS_COSTS_TOTAL) {
    console.log(
      `${name} has base stat costs sum of ${statCostsSum}. The expected sum is ${MOB_BASE_STATS_COSTS_TOTAL}`
    );
    return false;
  }
  return true;
};

export const areMobDropsInstalled = (mobData: MobModel) => {
  // Ensure that mob drops are actually installed upgrades for that mob
  const { name, drops, installedUpgrades } = mobData;

  for (const key in drops) {
    const rating = key as BattleRatings;
    const dropInfo = drops[rating];
    if (!!dropInfo) {
      const isUpgrade = !isSupplyItem(dropInfo.slug);
      if (!!isUpgrade) {
        const isDropInstalled = Object.values(installedUpgrades).includes(
          dropInfo.slug
        );
        if (!isDropInstalled) {
          console.log(
            `${name} has ${dropInfo.slug} as a drop but not an installed upgrade`
          );
          return false;
        }
      }
    }
  }
  return true;
};

export const areAllMobSkillsValid = (mobData: MobModel) => {
  // Ensure that all mob skills reference real skills
  const { name, skills } = mobData;

  for (const skill of skills) {
    const skillInfo = getSkillData(skill);

    if (!skillInfo) {
      console.log(`Mob ${name} has skill ${skill}, which doesn't exist`);
      return false;
    }
  }
  return true;
};

export const areUpgradeStatModifiersWeightsValid = (
  upgradeData: UpgradeModel
) => {
  // Ensure that upgrade base stat modifier weights sum up to 1 per corresponding base stat
  const { name, derivedStatsModifiers } = upgradeData;

  const statModsSums = Object.entries(derivedStatsModifiers).reduce(
    (acc, [key, value]) => {
      const derivedStat = key as keyof CharacterDerivedStats;
      const derivedStatInfo = getDerivedStatData(derivedStat);
      const baseStat = derivedStatInfo.baseStat;
      acc[baseStat] = (acc[baseStat] || 0) + value;
      return acc;
    },
    {} as RequirementsModel
  );

  for (const key in statModsSums) {
    const baseStat = key as keyof CharacterBaseStats;
    if (statModsSums[baseStat] !== 1) {
      console.log(
        `${name} has derived stat weight total of ${statModsSums[baseStat]} for base stat ${baseStat}. The expected total weight is 1`
      );
      return false;
    }
  }
  return true;
};

export const areUpgradeStatModifiersReqsValid = (upgradeData: UpgradeModel) => {
  // Ensure that upgrade base stat modifier weights sum up to 1
  const { name, derivedStatsModifiers, requirements } = upgradeData;

  for (const key in derivedStatsModifiers) {
    const derivedStat = key as keyof CharacterDerivedStats;
    const derivedStatInfo = getDerivedStatData(derivedStat);
    const baseStat = derivedStatInfo.baseStat;

    if (!requirements[baseStat]) {
      console.log(
        `${name} has derived stat ${derivedStatInfo.name}, but not the corresponding base stat requirement of ${baseStat}.`
      );
      return false;
    }
  }
  return true;
};

export const areDefaultPilotSkillsValid = (pilotData: PilotModel) => {
  // Ensure that default pilot skills have a medallion cost of 0
  const { skills } = pilotData;

  const defaultSkills = skills
    .map((value) => {
      const skillInfo = getSkillData(value);
      return skillInfo;
    })
    .filter((skillInfo) => {
      return !!skillInfo.isDefault;
    });

  for (const skill of defaultSkills) {
    if (skill.medallions > 0) {
      console.log(
        `Default skill ${skill.name} has a cost of ${skill.medallions}. The expected cost is 0`
      );
      return false;
    }
  }
  return true;
};

export const areAllPilotSkillsValid = (pilotData: PilotModel) => {
  // Ensure that all pilot skills reference real skills
  const { name, skills } = pilotData;

  for (const skill of skills) {
    const skillInfo = getSkillData(skill);

    if (!skillInfo) {
      console.log(`Pilot ${name} has skill ${skill}, which doesn't exist`);
      return false;
    }
  }
  return true;
};
