import { useEffect, useState } from "react";

function useDialog(dialog: string[], startOpen = false, hasNextAction = false) {
  const initialStep = startOpen ? 1 : 0;
  const [currentStep, setCurrentStep] = useState(initialStep);

  const hasNextStep = currentStep < dialog.length;
  const currentDialog = dialog[currentStep - 1];

  const handleNextStep = () => {
    const nextStep = hasNextStep ? currentStep + 1 : 0;
    setCurrentStep(nextStep);
  };

  const handleResetStep = () => {
    setCurrentStep(initialStep);
  };

  // Reset steps whenever the active dialog changes
  useEffect(handleResetStep, [dialog, initialStep]);

  return {
    currentStep,
    currentDialog,
    hasAction: hasNextAction || hasNextStep,
    onNextStep: handleNextStep,
  };
}

export default useDialog;
