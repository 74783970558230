import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Shop from "components/base/Shop";

export default function ShipworksShopBuy() {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Buy Upgrades</SectionTitle>
        <SectionContentScroll>
          <Shop isUpgrades isBuy shopSlug="bishopShipworksShop" />
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Buy Supplies</SectionTitle>
        <SectionContentScroll>
          <Shop isUpgrades={false} isBuy shopSlug="bishopShipworksShop" />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
