import { useDispatch, useSelector } from "react-redux";
import { disableMusic, enableMusic } from "redux/actions";
import { getCharacter } from "redux/selectors";
import {
  SettingName,
  SettingOption,
  SettingOptions,
  SettingsWrapper,
  SettingWrapper,
} from "components/base/Settings";

export default function SettingsMusic() {
  const dispatch = useDispatch();

  const {
    data: { settings },
  } = useSelector(getCharacter);

  const handleEnableMusic = () => {
    dispatch(enableMusic());
  };

  const handleDisableMusic = () => {
    dispatch(disableMusic());
  };

  return (
    <SettingsWrapper>
      <SettingWrapper>
        <SettingName>Background Music</SettingName>
        <SettingOptions>
          <SettingOption
            $isActive={!settings.isMusicEnabled}
            onClick={handleDisableMusic}
          >
            Off
          </SettingOption>
          <SettingOption
            $isActive={!!settings.isMusicEnabled}
            onClick={handleEnableMusic}
          >
            On
          </SettingOption>
        </SettingOptions>
      </SettingWrapper>
    </SettingsWrapper>
  );
}
