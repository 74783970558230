import {
  DataWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Inventory from "components/base/Inventory";
import Status from "components/base/Status";

export default function InventoryScreen() {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Status</SectionTitle>
        <SectionContent>
          <Status />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Supply Inventory</SectionTitle>
        <SectionContentScroll>
          <Inventory isUpgrades={false} canAction />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
