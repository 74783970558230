import healthImage from "assets/health.svg";
import expImage from "assets/exp.svg";
import creditsImage from "assets/credits.svg";
import medallionsImage from "assets/enhancements.svg";
import {
  formatAddNumber,
  formatNumber,
  roundTwoDecimals,
  roundWhole,
} from "utils/formatters";
import { Properties, PropertyModel } from "types";

export const PROPERTIES: Record<Properties, PropertyModel> = {
  health: {
    name: "Ship Health",
    image: healthImage,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  integrity: {
    name: "Components Integrity",
    image: healthImage,
    rounder: roundTwoDecimals,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  experience: {
    name: "Experience",
    image: expImage,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  credits: {
    name: "Credits",
    image: creditsImage,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
  medallions: {
    name: "Medallions",
    image: medallionsImage,
    rounder: roundWhole,
    formatter: formatNumber,
    addFormatter: formatAddNumber,
  },
};
