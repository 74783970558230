import styled, { css } from "styled-components";

type ButtonStyles = "normal" | "important" | "secondary";
type ButtonSizes = "xsmall" | "small" | "medium" | "large";

interface ButtonProps {
  $style: ButtonStyles;
  $size: ButtonSizes;
  $disabled?: boolean;
}

const COLORS: Record<ButtonStyles, string> = {
  normal: "#248791",
  important: "#c92e39",
  secondary: "#666666",
};

const SIZES: Record<ButtonSizes, string> = {
  xsmall: "12px",
  small: "16px",
  medium: "20px",
  large: "24px",
};

const PADDING: Record<ButtonSizes, string> = {
  xsmall: "4px 12px",
  small: "5px 15px",
  medium: "5px 15px",
  large: "5px 15px",
};

const disabledMixin = css`
  pointer-events: none;
  opacity: 0.25;
`;

const Button = styled.button.attrs(({ type = "button" }) => ({
  type,
}))<ButtonProps>`
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: ${(props) => PADDING[props.$size]};
  align-items: center;
  color: white;
  transition: all 100ms ease-in;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${(props) => SIZES[props.$size]};
  background-color: ${(props) => COLORS[props.$style]}60;
  border: 1px ${(props) => COLORS[props.$style]}80 solid;

  ${(props) => props.$disabled && disabledMixin}

  &:hover {
    filter: brightness(150%);
  }
`;

export default Button;
