import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  addEventLog,
  addLogMessage,
  changePlanetPlaylist,
  disableMovement,
  disableMusic,
  enableMovement,
  enableMusic,
  enterShip,
  exitBuilding,
  exitShip,
  getLogMessages,
  getOnlineUsers,
  hideInsideShip,
  keyboardCommands,
  move,
  pauseMusic,
  playMusic,
  playNextMusicTrack,
  setCurrentPlaylist,
  setCurrentTrack,
  setLogMessages,
  setMusicDisabled,
  setMusicEnabled,
  setOnlineUsers,
  setTrackChange,
  showInsideShip,
  showPlayerDetails,
  startMusicPlayer,
  stopMove,
  stopMusicPlayer,
  switchDataScreen,
  viewPlayerShip,
} from "redux/actions";
import { getCharacter, getFight, getGameState } from "redux/selectors";
import {
  EventLogPayload,
  KeyboardPayload,
  LogMessage,
  OnlineUser,
} from "types";
import { FIGHT_DIALOG_STATUSES } from "utils/constants";
import {
  getNextMusicTrack,
  getPlanetData,
  getPlaylistData,
  isTrackInPlaylist,
  shuffleList,
} from "utils/stats";
import {
  createEventLog,
  createLogMessage,
  loadOnlineUsers,
} from "utils/storage/firebase";

export function* keyboardCommandsSaga({
  payload,
}: {
  payload: KeyboardPayload;
}) {
  let {
    data: { location, ui },
  } = yield select(getCharacter);

  const { key, isKeyUp } = payload;
  switch (key) {
    case "left":
    case "a":
      // Move left
      if (isKeyUp) {
        yield put(stopMove());
      } else {
        yield put(move("left"));
      }
      break;
    case "right":
    case "d":
      // Move right
      if (isKeyUp) {
        yield put(stopMove());
      } else {
        yield put(move("right"));
      }
      break;
    case "esc":
      // Exit building or ship
      if (!!location.building) {
        yield put(exitBuilding());
      }
      if (!!ui.isInsideShip) {
        yield put(exitShip());
      }
      break;
    default:
      break;
  }
}

export function* getOnlineUsersSaga() {
  // Load list of online players
  const users: OnlineUser[] = yield loadOnlineUsers();
  const onlineUsers = Object.values(users);

  yield put(setOnlineUsers(onlineUsers));
}

function* enterShipSaga() {
  // Prevent entering ship when in a fight
  const { status } = yield select(getFight);
  const isFighting =
    status !== "notFighting" && !FIGHT_DIALOG_STATUSES.includes(status);
  if (isFighting) {
    return;
  }

  yield put(showInsideShip());

  yield put(disableMovement());
}

function* exitShipSaga() {
  yield put(hideInsideShip());

  yield put(enableMovement());
}

function* viewPlayerShipSaga({ payload: player }: { payload: string | null }) {
  yield call(enterShipSaga);

  yield put(switchDataScreen("comms"));

  yield put(showPlayerDetails(player));
}

function* addLogMessageSaga({ payload: text }: { payload: string }) {
  // Get user ID and user name
  let { userId, userName } = yield select(getCharacter);

  // TODO: Any message validation

  // Add new message in Firebase
  yield createLogMessage(userId, userName, text);
}

function* getLogMessagesSaga({ payload: messages }: { payload: LogMessage[] }) {
  // Should already be limited by amount
  const logMessages = Object.values(messages);

  yield put(setLogMessages(logMessages));
}

function* addEventLogSaga({ payload }: { payload: EventLogPayload }) {
  let {
    userId,
    userName,
    data: { ship, pilot, level },
  } = yield select(getCharacter);

  const { event, eventParams } = payload;

  yield createEventLog(
    userId,
    userName,
    ship,
    pilot,
    level,
    event,
    eventParams
  );
}

function* playMusicSaga() {
  // Always check player setting before turning music on
  let {
    data: { settings },
  } = yield select(getCharacter);

  if (!!settings.isMusicEnabled) {
    yield put(startMusicPlayer());
  }
}

function* pauseMusicSaga() {
  yield put(stopMusicPlayer());
}

function* enableMusicSaga() {
  // Check if current track is in current playlist, if not switch to first song
  let { currentTrack, currentPlaylist } = yield select(getGameState);
  const isInPlaylist = isTrackInPlaylist(currentTrack, currentPlaylist);

  yield put(setMusicEnabled());

  if (!isInPlaylist) {
    yield call(playNextMusicTrackSaga);
  }

  yield call(playMusicSaga);

  yield put(
    addEventLog({
      event: "enable_music",
    })
  );
}

function* disableMusicSaga() {
  yield put(setMusicDisabled());
  yield call(pauseMusicSaga);
}

function* playNextMusicTrackSaga() {
  let { currentTrack, currentPlaylist } = yield select(getGameState);

  const nextTrack = getNextMusicTrack(currentTrack, currentPlaylist);

  yield put(setCurrentTrack(nextTrack));
  // Trigger play of new track, even if repeating same track
  yield put(setTrackChange());
}

export function* setTitleScreenPlaylistSaga() {
  const playlistData = getPlaylistData("titleScreen");

  const randomizedPlaylist = shuffleList(playlistData);

  yield put(setCurrentPlaylist(randomizedPlaylist));
}

function* changePlanetPlaylistSaga() {
  let {
    data: { location },
  } = yield select(getCharacter);
  const planetData = getPlanetData(location.planet);
  const playlistData = getPlaylistData(planetData.playlist);

  const randomizedPlaylist = shuffleList(playlistData);

  yield put(setCurrentPlaylist(randomizedPlaylist));
}

export default function* gameSagas() {
  yield takeLeading(keyboardCommands, keyboardCommandsSaga);
  yield takeLeading(getOnlineUsers, getOnlineUsersSaga);
  yield takeLeading(enterShip, enterShipSaga);
  yield takeLeading(exitShip, exitShipSaga);
  yield takeLeading(viewPlayerShip, viewPlayerShipSaga);
  yield takeLeading(addLogMessage, addLogMessageSaga);
  yield takeLeading(getLogMessages, getLogMessagesSaga);
  yield takeLeading(addEventLog, addEventLogSaga);
  yield takeLeading(playMusic, playMusicSaga);
  yield takeLeading(pauseMusic, pauseMusicSaga);
  yield takeLeading(enableMusic, enableMusicSaga);
  yield takeLeading(disableMusic, disableMusicSaga);
  yield takeLeading(playNextMusicTrack, playNextMusicTrackSaga);
  yield takeLeading(changePlanetPlaylist, changePlanetPlaylistSaga);
}
