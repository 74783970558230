import { ShipModel } from "types";

import hyperionShipImage from "assets/starship-hyperion.svg";
import hyperionInteriorImage from "assets/interior-ship-hyperion.svg";
import nixShipImage from "assets/starship-nix.svg";
import nixInteriorImage from "assets/interior-ship-nix.svg";
import titaniaShipImage from "assets/starship-titania.svg";
import titaniaInteriorImage from "assets/interior-ship-titania.svg";
import calypsoShipImage from "assets/starship-calypso.svg";
import calypsoInteriorImage from "assets/interior-ship-calypso.svg";
import atlasShipImage from "assets/starship-atlas.svg";
import atlasInteriorImage from "assets/interior-ship-atlas.svg";

export interface ShipsData {
  [key: string]: ShipModel;
}

export const SHIPS: ShipsData = {
  hyperion: {
    name: "Hyperion",
    description:
      "Hyperion class starships have strong weaponry that can potentially inflict high damage, but their accuracy and consistency is questionable.",
    color: "#e20a0a",
    images: {
      ship: hyperionShipImage,
      interior: hyperionInteriorImage,
    },
    partPositions: {
      weapons: { x: 45, y: 55.75 },
      targeting: { x: 62, y: 55.75 },
      thrusters: { x: 21.2, y: 50.75 },
    },
    startingBaseStats: {
      firepower: 4,
      resilience: 3,
      speed: 2,
      precision: 0,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 2,
    },
  },
  nix: {
    name: "Nix",
    description:
      "Nix class starships are quick and nimble, allowing them to attack quickly and dodge often, but they have almost no shields.",
    color: "#b50cba",
    images: {
      ship: nixShipImage,
      interior: nixInteriorImage,
    },
    partPositions: {
      weapons: { x: 45, y: 53.2 },
      targeting: { x: 53, y: 53.2 },
      thrusters: { x: 33, y: 49.2 },
    },
    startingBaseStats: {
      firepower: 1,
      resilience: 0,
      speed: 4,
      precision: 3,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 3,
      speed: 1,
      precision: 2,
      energy: 2,
    },
  },
  titania: {
    name: "Titania",
    description:
      "Titania class starships have incredibly resilient shields, but are extremely slow moving.",
    color: "#1fa000",
    images: {
      ship: titaniaShipImage,
      interior: titaniaInteriorImage,
    },
    partPositions: {
      weapons: { x: 85, y: 55.8 },
      targeting: { x: 93, y: 55.8 },
      thrusters: { x: 12.8, y: 50.3 },
    },
    startingBaseStats: {
      firepower: 2,
      resilience: 4,
      speed: 0,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
  },
  calypso: {
    name: "Calypso",
    description:
      "Calypso class starships are equipped with powerful stores of antimatter, allowing for unique abilities, but their regular attacks are quite ineffective.",
    color: "#12b0e0",
    images: {
      ship: calypsoShipImage,
      interior: calypsoInteriorImage,
    },
    partPositions: {
      weapons: { x: 39, y: 56 },
      targeting: { x: 47, y: 56 },
      thrusters: { x: 26, y: 53 },
    },
    startingBaseStats: {
      firepower: 0,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
  },
  atlas: {
    name: "Atlas",
    description:
      "Atlas class starships will strike enemies where it hurts with pinpoint accuracy and consistency, but they're less able to employ special abilities.",
    color: "#f2eb00",
    images: {
      ship: atlasShipImage,
      interior: atlasInteriorImage,
    },
    partPositions: {
      weapons: { x: 62, y: 43.5 },
      targeting: { x: 70, y: 43.5 },
      thrusters: { x: 19.5, y: 50.75 },
    },
    startingBaseStats: {
      firepower: 3,
      resilience: 2,
      speed: 1,
      precision: 4,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
  },
};
