import "css-doodle";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import gameplayVideo from "assets/lvgameplay.mp4";
import { titleAnimation } from "components/styles/animations";
import {
  continueAsCharacter,
  disableMusic,
  enableMusic,
  playMusic,
  setAuthOverlayScreen,
  setMainMenuScreen,
  showAuthOverlay,
} from "redux/actions";
import { getCharacter, getGameState } from "redux/selectors";
import { MainMenuScreens } from "types";
import { useImmer } from "use-immer";
import MainMenuBackground from "./MainMenuBackground";
import NewCharacter from "./NewCharacter";

interface MainMenuDisplayProps {
  $isHidden: boolean;
}

interface GameplayPreviewProps {
  $isShowing: boolean;
}

interface TitleInnerProps {
  $isHidden: boolean;
}

interface TitleActionsProps {
  $isHidden: boolean;
}

interface MusicWrapperProps {
  $isHidden: boolean;
}

interface MusicOptionProps {
  $isActive: boolean;
}

interface MainMenuState {
  isShowGameplay: boolean;
}

const titleHiddenMixin = css`
  transform: scale(8) rotate(5deg);
  opacity: 0;
  pointer-events: none;
`;

const newCharHiddenMixin = css`
  opacity: 0;
  pointer-events: none;
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`;

const GameplayPreview = styled.video<GameplayPreviewProps>`
  position: absolute;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  height: 56.25vw; /* 100 * 9 / 16 */
  object-fit: fill;
  min-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  ${(props) => props.$isShowing && `opacity: 1`};
  transition: all 500ms ease-in;
  z-index: 5;
`;

const PreviewButton = styled.div`
  right: 5vw;
  bottom: 10vh;
  position: absolute;
  z-index: 6;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: #ffffff25;
  transition: all 500ms ease-in;

  :hover {
    background-color: #ffffff50;
  }
`;

const MainMenuContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: relative;
`;

const TitleWrapper = styled.div<MainMenuDisplayProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(1) rotate(0deg);
  transition: all 1s linear;

  ${(props) => props.$isHidden && titleHiddenMixin}
`;

const TitleInner = styled.div<TitleInnerProps>`
  position: relative;
  transition: all 500ms ease-in;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${(props) => props.$isHidden && `opacity: 0;`}
`;

const MainTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  pointer-events: none;

  animation: ${titleAnimation} 10s linear infinite;
`;

const Title = styled.div`
  font-family: "AlphaCentauri", sans-serif;
  font-size: 100px;
  line-height: 0.9;
  letter-spacing: 18px;
  text-shadow: 0 0 5px white;
`;

const Description = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 10px;
  opacity: 0.8;
`;

const SubDescription = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.8;
`;

const TitleActions = styled.div<TitleActionsProps>`
  position: absolute;
  bottom: -120px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 500ms ease-in;

  ${(props) => props.$isHidden && `opacity: 0;`}
`;

const Option = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #43fffa25;
  transition: all 100ms ease-in;
  cursor: pointer;
  box-shadow: 0 0px 10px #ffffff20;
  transform: skew(-30deg);
  border: 1px #43fffa50 solid;
  padding: 8px 30px;
`;

const AuthOption = styled(Option)`
  @media screen and (max-width: 480px) {
    display: none;
  }

  :hover {
    background-color: #43fffa50;
    border: 1px #43fffa80 solid;
    box-shadow: 0 0px 15px #ffffff40;
    transform: skew(-30deg) scale(1.05);
  }
`;

const MobileOption = styled(Option)`
  background-color: #cccccc25;
  border-color: #cccccc50;
  cursor: auto;

  @media screen and (min-width: 481px) {
    display: none;
  }
`;

const OptionInner = styled.div`
  color: #ffffff;
  font-size: 20px;
  transform: skew(30deg);
`;

const MusicWrapper = styled.div<MusicWrapperProps>`
  left: 5vw;
  bottom: 10vh;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 500ms ease-in;

  ${(props) => props.$isHidden && `opacity: 0;`}
`;

const MusicOptions = styled.div`
  display: flex;
`;

const activeSettingsMixin = css`
  opacity: 1;
  background-color: #ffffff50;
  cursor: auto;
  border-color: #ffffff;
`;

const MusicOption = styled.div<MusicOptionProps>`
  opacity: 0.5;
  transition: all 200ms ease-in;
  cursor: pointer;
  padding: 5px 20px;
  background-color: #ffffff25;
  border: 1px transparent solid;

  :hover {
    opacity: 1;
  }

  ${(props) => props.$isActive && activeSettingsMixin}
`;

const NewCharacterWrapper = styled.div<MainMenuDisplayProps>`
  transition: opacity 1s linear;
  width: 100%;
  height: 100%;

  ${(props) => props.$isHidden && newCharHiddenMixin}
`;

export default function MainMenu() {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { isInitialDataLoaded, mainMenuScreen, isNewUser } =
    useSelector(getGameState);
  const {
    userName,
    data: { settings },
  } = useSelector(getCharacter);

  const initialMainMenuState: MainMenuState = {
    isShowGameplay: false,
  };
  const [state, setState] = useImmer<MainMenuState>(initialMainMenuState);

  const goToScreen = (status: MainMenuScreens) => {
    dispatch(setMainMenuScreen(status));
  };

  const handleEnableMusic = () => {
    dispatch(enableMusic());
  };

  const handleDisableMusic = () => {
    dispatch(disableMusic());
  };

  const startMusic = () => {
    dispatch(playMusic());
  };

  const handlePlayVideo = () => {
    setState((draft) => {
      draft.isShowGameplay = true;
    });
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleStopVideo = () => {
    setState((draft) => {
      draft.isShowGameplay = false;
    });
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <MainMenuWrapper>
      <BackgroundWrapper>
        <MainMenuBackground />
        <GameplayPreview
          ref={videoRef}
          src={gameplayVideo}
          muted
          loop
          $isShowing={state.isShowGameplay}
        />
      </BackgroundWrapper>
      <MainMenuContent>
        <TitleWrapper $isHidden={mainMenuScreen !== "title"}>
          <TitleInner $isHidden={state.isShowGameplay}>
            <MainTitle>
              <Title>
                LIGHT <br />
                VOYAGERS
              </Title>
              <Description>
                intergalactic adventure awaits with open arms
              </Description>
              <SubDescription>...Well, mostly open</SubDescription>
            </MainTitle>
            <TitleActions $isHidden={state.isShowGameplay}>
              {/* Auth exists, anonymous or account, with existing character */}
              {!isNewUser && !!userName && (
                <AuthOption
                  onClick={() => {
                    dispatch(continueAsCharacter());
                    startMusic();
                  }}
                >
                  <OptionInner>Continue as {userName}</OptionInner>
                </AuthOption>
              )}
              {/* No auth exists, anonymous or account */}
              {!!isNewUser && (
                <AuthOption
                  onClick={() => {
                    dispatch(setAuthOverlayScreen("login"));
                    dispatch(showAuthOverlay());
                    startMusic();
                  }}
                >
                  <OptionInner>Log In</OptionInner>
                </AuthOption>
              )}
              {/* No character created yet */}
              {!!isInitialDataLoaded && !userName && (
                <AuthOption
                  onClick={() => {
                    goToScreen("newCharacter");
                    startMusic();
                  }}
                >
                  <OptionInner>New Character</OptionInner>
                </AuthOption>
              )}
              <MobileOption>
                <OptionInner>Mobile Version Coming</OptionInner>
              </MobileOption>
            </TitleActions>
          </TitleInner>
          <PreviewButton
            onMouseEnter={handlePlayVideo}
            onMouseLeave={handleStopVideo}
          >
            See Gameplay
          </PreviewButton>
          <MusicWrapper $isHidden={state.isShowGameplay}>
            Music
            <MusicOptions>
              <MusicOption
                $isActive={!settings.isMusicEnabled}
                onClick={handleDisableMusic}
              >
                Off
              </MusicOption>
              <MusicOption
                $isActive={!!settings.isMusicEnabled}
                onClick={handleEnableMusic}
              >
                On
              </MusicOption>
            </MusicOptions>
          </MusicWrapper>
        </TitleWrapper>
        <NewCharacterWrapper $isHidden={mainMenuScreen !== "newCharacter"}>
          <NewCharacter onBack={() => goToScreen("title")} />
        </NewCharacterWrapper>
      </MainMenuContent>
    </MainMenuWrapper>
  );
}
