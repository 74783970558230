import styled from "styled-components";

import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionGroup,
  SectionGroups,
  SectionSubtitle,
  SectionTitle,
} from "components/base/DataScreen";
import SettingsAuth from "components/ui/SettingsAuth";
import SettingsMusic from "./SettingsMusic";

const Link = styled.a`
  color: white;
`;

const CreditWrapper = styled.div`
  display: inline;
`;

export default function SettingsScreen() {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Game Settings</SectionTitle>
        <SectionContentScroll>
          <SectionGroups>
            <SectionGroup>
              <SectionSubtitle>Audio</SectionSubtitle>
              <SettingsMusic />
            </SectionGroup>
            <SectionGroup>
              <SectionSubtitle>Account</SectionSubtitle>
              <SettingsAuth />
            </SectionGroup>
          </SectionGroups>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Credits</SectionTitle>
        <SectionContentScroll>
          <CreditWrapper>
            The title screen background is a modified version of this{" "}
            <Link
              href="https://codepen.io/yuanchuan/pen/wZJqNK"
              target="_blank"
            >
              Codepen
            </Link>
            .
          </CreditWrapper>
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
