import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import healthImage from "assets/health.svg";
import {
  DataWrapper,
  ScreenButtonWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import LargeBar from "components/base/LargeBar";
import Shop from "components/base/Shop";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { buyRepair } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { COLORS } from "utils/constants";
import { getRepairCost, getShipDamage } from "utils/stats";
import Button from "components/base/Button";

interface IconProps {
  image: string;
}

const Credits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const HealthWrapper = styled.div``;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export default function ShipworksRepair() {
  const dispatch = useDispatch();
  const {
    data: { health, derivedStats },
  } = useSelector(getCharacter);

  const shipDamage = getShipDamage(
    health,
    derivedStats.current.complete.maxHealth
  );
  const repairCost = getRepairCost(
    health,
    derivedStats.current.complete.maxHealth
  );

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Repair Starship</SectionTitle>
        <SectionContent>
          <Name>Ship Health</Name>
          <HealthWrapper>
            <LargeBar
              icon={healthImage}
              number={health}
              maxNumber={derivedStats.current.complete.maxHealth}
              color={COLORS.HEALTH}
              name="Health"
              description="This is how much health your ship currently has. Don't let it drop to zero! You can fully repair your starship at the nearest Shipworks in any city."
            />
          </HealthWrapper>
          <ScreenButtonWrapper>
            <Trigger>
              <Button
                $style="normal"
                $size="medium"
                $disabled={!shipDamage}
                onClick={() => dispatch(buyRepair({ credits: repairCost }))}
              >
                Repair
                <Credits>
                  <CreditsIcon image={creditsImage} />
                  {repairCost}
                </Credits>
              </Button>
              <Tooltip isAbove>
                <TooltipName>Repair Ship</TooltipName>
                <TooltipDesc>Have your ship fully repaired.</TooltipDesc>
              </Tooltip>
            </Trigger>
          </ScreenButtonWrapper>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Buy Repair Kits</SectionTitle>
        <SectionContentScroll>
          <Shop isUpgrades={false} isBuy shopSlug="bishopShipworksRepair" />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
