import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Avatar from "components/ui/Avatar";
import BuildingInterior from "components/ui/BuildingInterior";
import CharacterInfo from "components/ui/CharacterInfo";
import Fight from "components/ui/Fight";
import ShipInterior from "components/ui/ShipInterior";
import World from "components/world/World";
import { keyboardCommands } from "redux/actions";

const MapWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

const KEYBOARD_KEYS = "left,right,a,d,esc";

export default function GameMap() {
  const dispatch = useDispatch();

  useHotkeys(KEYBOARD_KEYS, (event, handler) => {
    event.preventDefault();
    dispatch(
      keyboardCommands({
        key: handler.shortcut,
        isKeyUp: false,
      })
    );
  });

  useHotkeys(
    KEYBOARD_KEYS,
    (event, handler) => {
      event.preventDefault();
      dispatch(
        keyboardCommands({
          key: handler.shortcut,
          isKeyUp: true,
        })
      );
    },
    { keyup: true }
  );

  return (
    <MapWrapper>
      <World>
        <Avatar />
      </World>
      <ShipInterior />
      <BuildingInterior />
      <CharacterInfo />
      <Fight />
    </MapWrapper>
  );
}
