import React from "react";
import styled from "styled-components";

import {
  backgroundMoveLeftAnimation,
  backgroundMoveRightAnimation,
} from "components/styles/animations";

interface BackgroundShipProps {
  duration: number;
  height: number;
  verticalPosition: number;
  isMovingRight: boolean;
  image: string;
}

interface BackgroundShipWrapperProps {
  duration: number;
  height: number;
  verticalPosition: number;
  isMovingRight: boolean;
}

const BackgroundShipWrapper = styled.div<BackgroundShipWrapperProps>`
  position: absolute;
  top: ${(props) => props.verticalPosition}%;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}%;
  animation-name: ${(props) =>
    props.isMovingRight
      ? backgroundMoveRightAnimation
      : backgroundMoveLeftAnimation};
  animation-duration: ${(props) => props.duration}s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const BackgroundShipImage = styled.img`
  height: 100%;
  width: auto;
`;

export default function BackgroundShip({
  duration,
  height,
  verticalPosition,
  isMovingRight,
  image,
}: BackgroundShipProps) {
  return (
    <BackgroundShipWrapper
      duration={duration}
      height={height}
      verticalPosition={verticalPosition}
      isMovingRight={isMovingRight}
    >
      <BackgroundShipImage src={image} />
    </BackgroundShipWrapper>
  );
}
