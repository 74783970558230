import styled, { css } from "styled-components";
import parse from "html-react-parser";
import { SkillModel } from "types";

import Tooltip, {
  TooltipName,
  TooltipDesc,
  TooltipInfo,
  Trigger,
  TooltipRecharge,
  RechargeIcon,
} from "./Tooltip";
import energyImage from "assets/stat-energy.svg";
import { COLORS } from "utils/constants";
import { useSelector } from "react-redux";
import { getFight } from "redux/selectors";
import { getDerivedStatData } from "utils/stats";

interface SkillProps {
  skill: SkillModel;
  characterData: any;
  isSmall?: boolean;
  isShowValues?: boolean;
  isTooltipAbove?: boolean;
  isRecharging?: boolean;
  rechargeTurns?: number;
  rechargePercentage?: number;
  onClick?: () => void;
}

interface SkillWrapperProps {
  isSmall: boolean;
}

interface SkillRechargeProps {
  percentage: number;
}

interface SkillImageProps {
  isRecharging: boolean;
}

const smallMixin = css`
  height: 30px;
  width: 30px;
`;

const SkillWrapper = styled.div<SkillWrapperProps>`
  border-radius: 4px;
  overflow: hidden;
  height: 44px;
  width: 44px;
  cursor: pointer;
  position: relative;

  ${(props) => props.isSmall && smallMixin}

  &:hover {
    filter: saturate(150%);
  }
`;

const SkillRechargeValue = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 2;
`;

const SkillRecharge = styled.div<SkillRechargeProps>`
  height: ${(props) => props.percentage}%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.8;
  transition: all 200ms ease-out;
  z-index: 1;
`;

const SkillImage = styled.img<SkillImageProps>`
  width: 100%;
  height: 100%;
  ${(props) => props.isRecharging && `filter: saturate(0%) brightness(50%);`}
`;

const SkillValues = styled.div`
  strong {
    color: ${COLORS.ENERGY};
  }
`;

const Skill = ({
  skill,
  characterData,
  isSmall = false,
  isShowValues = false,
  isTooltipAbove = false,
  isRecharging = false,
  rechargeTurns = 0,
  rechargePercentage = 0,
  onClick,
}: SkillProps) => {
  const boostInfo = getDerivedStatData("energyMultiplier");
  const fightData = useSelector(getFight);

  return (
    <Trigger>
      <SkillWrapper onClick={onClick} isSmall={isSmall}>
        {!!isRecharging && (
          <SkillRechargeValue>{rechargeTurns}</SkillRechargeValue>
        )}
        <SkillRecharge percentage={rechargePercentage} />
        <SkillImage src={skill.image} isRecharging={isRecharging} />
      </SkillWrapper>
      <Tooltip isAbove={isTooltipAbove}>
        <TooltipName>{skill.name}</TooltipName>
        <TooltipDesc>{skill.description}</TooltipDesc>
        {!!skill.values && isShowValues && (
          <TooltipDesc>
            <SkillValues>
              {parse(skill.values({ characterData, fightData }))}
            </SkillValues>
          </TooltipDesc>
        )}
        <TooltipInfo>
          {!!skill.hasAntimatterBoost && isShowValues && (
            <TooltipRecharge>
              <RechargeIcon image={energyImage} />
              {`Antimatter Boost: ${boostInfo.formatter(
                characterData.derivedStats.current.complete.energyMultiplier
              )}`}
            </TooltipRecharge>
          )}
          <TooltipRecharge>
            <RechargeIcon image={energyImage} />
            {`Turns to Recharge: ${skill.recharge}`}
          </TooltipRecharge>
        </TooltipInfo>
      </Tooltip>
    </Trigger>
  );
};

export default Skill;
